import { AxiosInstance } from "axios";

export class PaymentHistoryService {
  private httpClient: AxiosInstance;
  private path: string = "paymenthistory";

  constructor(httpClient: AxiosInstance) {
    this.httpClient = httpClient;
  }

  public async getlistmapping() {
    return await this.httpClient.get(`${this.path}/gethistoryfilter`, {
      withCredentials: false,
    });
  }

  public async getlist(page: number, limit: number) {
    return await this.httpClient.get(
      `${this.path}/gethistory?page=${page}&limit=${limit}`,
      {
        withCredentials: false,
      }
    );
  }

  public async getFilterList(
    data: {
      eventTransactionId: any;
      eventFirstName: any;
      eventEmail: any;
      eventpgName: any;
      eventpgStatus: any;
      eventPaymentMode: any;
      eventAmount: any;
      eventCreatedDateTime: any;
    },
    page: number,
    limit: number
  ) {
    return await this.httpClient.get(
      `${this.path}/gethistory?transaction_id=${data.eventTransactionId}&&first_name=${data.eventFirstName}&&email=${data.eventEmail}&&pg_name=${data.eventpgName}&&pg_status=${data.eventpgStatus}&&payment_mode=${data.eventPaymentMode}&&amount=${data.eventAmount}&&created_date_time=${data.eventCreatedDateTime}&&page=${page}&limit=${limit}`,
      {
        withCredentials: false,
      }
    );
  }
}
