import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useAuthentication } from "../util/Authentication";
interface PrivateRouterProps {}

export const PrivateRouter: React.FC<PrivateRouterProps> = () => {
  let auth = useAuthentication().isAuthenticated;

  return auth ? <Outlet /> : <Navigate to="/login" />;
};
