import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Form, Table, Button } from "semantic-ui-react";
import { Link } from "react-router-dom";
import TablePagination from "@mui/material/TablePagination";
import Autocomplete from "@material-ui/lab/Autocomplete";
import RefreshIcon from "@mui/icons-material/Refresh";
import TextField from "@material-ui/core/TextField";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteSweepIcon from "@mui/icons-material/DeleteSweep";
import EditIcon from "@mui/icons-material/Edit";
import Sidebar from "../Sidebar";
import { AxiosHTTPClient } from "../../util/AxiosInstance";
import { PaymentNameService } from "../../service/paymetNameService";
import { useNotifications } from "reapop";

type FormValues = {
  name: string;
  status: number;
};

const filterdata = [
  { name: "Active", value: 1 },
  { name: "InActive", value: 0 },
];

const PaymentName: React.FC<any> = () => {
  const { notify } = useNotifications();
  const {
    register,
    handleSubmit,
    setError,
    setValue,
    formState: { errors },
  } = useForm<FormValues>();

  const [isChecked, setChecked] = useState<any[]>([]);
  const [apiData, setApiData] = useState([]);
  const [paymentduplicate, setPaymentduplicate] = useState("");
  const [page, setPage] = useState(0);
  const perPage = 10;
  const [filterstatus, setFilterStatus] = useState("");
  const [filterName, setFilterName] = useState("");
  const [selectallUncheck, setSelectallUncheck] = useState(false);

  const AxiosInstance = AxiosHTTPClient();
  //service Api
  const paymentNameService: PaymentNameService = new PaymentNameService(
    AxiosInstance
  );

  const handleDelete = (e: any) => {
    const { value, checked } = e.target;
    if (checked) {
      setChecked([...isChecked, value]);
    } else {
      setChecked(isChecked.filter((e) => e !== value));
    }
  };

  const handleSelectAll = (e: any) => {
    const { value, checked } = e.target;
    let checkedData: any = [];
    setSelectallUncheck(checked);
    if (checked)
      apiData.map((item: any) => {
        checkedData = [...checkedData, item.id];
      });
    setChecked(checkedData);
  };

  //Add Payment Method Name
  const sendDataToAPI = (data: FormValues) => {
    paymentNameService.paymentAdd(data).then((res) => {
      if (res.data.status === false) {
        setError("name", {
          message: res.data.message,
        });
      } else {
        getData();
        notify({ message: res.data.message, status: "success" });
        setValue("name", "");
        setValue("status", 1);
      }
    });
  };

  //Getdata
  const getData = async () => {
    try {
      paymentNameService.getlist().then((res) => {
        setApiData(res.data.responce);
      });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  //delete data
  const onDelete = (id: any) => {
    var option = window.confirm(`Are you sure delete`);
    if (option) {
      paymentNameService.deletePayment(id).then((res) => {
        if (res.data.status == false) {
          setPaymentduplicate(res.data.message);
        } else {
          getData();
          notify({ message: res.data.message, status: "success" });
          setPaymentduplicate("");
        }
      });
    }
  };

  //Deteteall
  const alldelete = async () => {
    var option = window.confirm(`Are you sure delete`);
    if (isChecked) {
      if (option) {
        paymentNameService.deleteall({ isChecked }).then((res) => {
          if (res.data.status == false) {
            setPaymentduplicate(res.data.message);
          } else {
            getData();
            setSelectallUncheck(false);
            notify({ message: res.data.message, status: "success" });
            setPaymentduplicate("");
            getData();
            setChecked([]);
          }
        });
      }
    }
  };

  //filter data
  const handlefilter = (eventFilterName: any, eventStatus: any) => {
    eventFilterName = eventFilterName || "";
    const data: any = {
      eventFilterName: eventFilterName,
      eventStatus: eventStatus,
    };
    paymentNameService.getFilterList(data).then((res) => {
      setApiData(res.data.responce);
    });
  };

  //sync data
  const sync = async () => {
    paymentNameService.sync().then((getData) => {
      setApiData(getData.data.responce);
    });
    setFilterName("");
    setFilterStatus("");
    setPaymentduplicate("");
    setChecked([]);
  };

  return (
    <>
      <div style={{ display: "flex" }}>
        <Sidebar />
        <div className="Paymemtform">
          <h3 className="horizontalline">Payment Method</h3>

          <Form onSubmit={handleSubmit(sendDataToAPI)} autoComplete="off">
            <div className="form_column">
              <Form.Field className="form_input">
                <label>
                  Name <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  placeholder="Payment Method"
                  {...register("name", {
                    required: "Payment Method is required.",
                  })}
                  // onChange={(e) => {
                  //   setValue("name", e.target.value.replace(/[^A-Za-z]/gi, ""));
                  // }}
                  id="name"
                  maxLength={50}
                />

                <div style={{ color: "red" }}>
                  {errors.name && errors.name.message}
                </div>
              </Form.Field>

              <Form.Field className="form_input">
                <label>Status</label>
                <select {...register("status")} id="status">
                  <option value="1">Active</option>
                  <option value="0">Inactive</option>
                </select>
              </Form.Field>
            </div>
            <div className="form_submit">
              <Button
                type="submit"
                style={{ color: "white", backgroundColor: "rgb(255 144 0)" }}
              >
                Add
              </Button>
            </div>
          </Form>

          <div className="sync_button">
            {apiData.length !== 0 ? (
              <div>
                {isChecked.length > 1 && (
                  <Button
                    style={{
                      color: "white",
                      backgroundColor: "rgb(255 144 0)",
                    }}
                    onClick={alldelete}
                  >
                    <DeleteSweepIcon /> Delete All
                  </Button>
                )}
              </div>
            ) : (
              <div></div>
            )}
            <div>
              <p>Reset Filter</p>
              <Button
                style={{ color: "white", backgroundColor: "rgb(255 144 0)" }}
                onClick={sync}
              >
                <RefreshIcon />
              </Button>
            </div>
          </div>

          {paymentduplicate ? (
            <div style={{ color: "red" }}>{paymentduplicate}</div>
          ) : (
            ""
          )}

          <Table celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell style={{ width: "3%", textAlign: "center" }}>
                  <input
                    type="checkbox"
                    checked={selectallUncheck}
                    onChange={(e) => handleSelectAll(e)}
                  />
                </Table.HeaderCell>

                <Table.HeaderCell style={{ width: "25%", textAlign: "center" }}>
                  <div className="autocomplete">
                    <Autocomplete
                      options={apiData.map((data: any) => data.name)}
                      style={{ width: 300 }}
                      renderInput={(params) => (
                        <TextField
                          placeholder="Payment Method Name"
                          {...params}
                          variant="outlined"
                        />
                      )}
                      value={filterName}
                      onChange={(event, value) => {
                        setFilterName(value);
                        handlefilter(value, filterstatus);
                      }}
                    />
                    <div style={{ marginLeft: "6px" }}>
                      <FilterAltIcon className="filterIcon" />
                    </div>
                  </div>
                </Table.HeaderCell>
                <Table.HeaderCell style={{ width: "12%", textAlign: "center" }}>
                  <div className="autocomplete">
                    <div className="filterstatus">
                      <FormControl sx={{ m: 1, minWidth: 120 }}>
                        <Select
                          value={filterstatus}
                          onChange={(event) => {
                            setFilterStatus(event.target.value);
                            handlefilter(filterName, event.target.value);
                          }}
                          style={{
                            width: "120px",
                            textAlign: "center",
                            height: "45px",
                          }}
                          displayEmpty
                          inputProps={{ "aria-label": "Without label" }}
                        >
                          {filterdata?.map((data: any, index: any) => (
                            <MenuItem
                              key={index}
                              value={data.value}
                              style={{
                                display: "block",
                                width: "100px",
                                textAlign: "center",
                                padding: "5px",
                              }}
                            >
                              {data.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                    <div style={{ marginLeft: "6px" }}>
                      <FilterAltIcon className="filterIcon" />
                    </div>
                  </div>
                </Table.HeaderCell>

                <Table.HeaderCell
                  style={{ width: "12%", textAlign: "center" }}
                  colSpan={2}
                >
                  Action
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {apiData
                .slice(page * perPage, (page + 1) * perPage)
                .map((data: any, index: any) => {
                  return (
                    <Table.Row key={index}>
                      <Table.Cell style={{ textAlign: "center" }}>
                        <input
                          type="checkbox"
                          value={data.id}
                          checked={isChecked.find(
                            (item: any) => item === data.id
                          )}
                          onChange={(e) => handleDelete(e)}
                        />
                      </Table.Cell>

                      <Table.Cell>{data.name}</Table.Cell>
                      <Table.Cell style={{ textAlign: "center" }}>
                        {data.status == 1 ? "Active" : "Inactive"}
                      </Table.Cell>
                      <Table.Cell style={{ textAlign: "center" }}>
                        <Link to={`/payment_name_update/${data.id}`}>
                          <Button
                            style={{
                              color: "white",
                              backgroundColor: "rgb(255 144 0)",
                            }}
                          >
                            <EditIcon />
                          </Button>
                        </Link>
                      </Table.Cell>
                      <Table.Cell style={{ textAlign: "center" }}>
                        <Button
                          className="flex "
                          style={{
                            color: "white",
                            backgroundColor: "rgb(255 144 0)",
                          }}
                          onClick={() => onDelete(data.id)}
                        >
                          <DeleteIcon />
                        </Button>
                      </Table.Cell>
                    </Table.Row>
                  );
                })}
            </Table.Body>
          </Table>
          {apiData.length > 0 ? (
            <TablePagination
              component="div"
              count={apiData.length}
              page={page}
              onPageChange={(e, newPage) => setPage(newPage)}
              rowsPerPage={perPage}
              rowsPerPageOptions={[]}
            />
          ) : (
            <div>No data found</div>
          )}
        </div>
      </div>
    </>
  );
};

export default PaymentName;
