import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Table, Button } from "semantic-ui-react";
import { Link } from "react-router-dom";
import TablePagination from "@mui/material/TablePagination";
import RefreshIcon from "@mui/icons-material/Refresh";
import TextField from "@material-ui/core/TextField";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteSweepIcon from "@mui/icons-material/DeleteSweep";
import EditIcon from "@mui/icons-material/Edit";
import Sidebar from "../Sidebar";
import { AxiosHTTPClient } from "../../util/AxiosInstance";
import { StoreDetailsService } from "../../service/StoreDetailsService";
import { useNotifications } from "reapop";
import TableContainer from "@material-ui/core/TableContainer";
import { NavLink } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import { Card } from "@mui/material";

type FormValues = {
  code: string;
  store_id: string;
  imei: string;
  pos_id: string;
  device_no: string;
  hw_id: string;
  merchant_id: string;
  security_token: string;
  merchant_name: string;
  store_name: string;
};

const StoreDetails: React.FC<any> = () => {
  const { notify } = useNotifications();
  const {
    setError,
    formState: { errors },
  } = useForm<FormValues>();

  const [isChecked, setChecked] = useState<any[]>([]);
  const [apiData, setApiData] = useState([]);
  const [page, setPage] = useState(0);
  const perPage = 10;
  const [filterCode, setFilterCode] = useState("");
  const [filterStoreId, setfilterStoreId] = useState("");
  const [filterIMEI, setfilterIMEI] = useState("");
  const [filterPosId, setfilterPosId] = useState("");
  const [filterDeviceNo, setFilterDeviceNo] = useState("");
  const [filterHwId, setfilterHwId] = useState("");
  const [filterMerchantId, setMerchantId] = useState("");
  const [filterSecurityToken, setfilterSecurityToken] = useState("");
  const [filterMerchantName, setFilterMerchantName] = useState("");
  const [filterStoreName, setfilterStoreName] = useState("");
  // const [filterMerchantCode, setFilterMerchantCode] = useState("");
  const [selectallUncheck, setSelectallUncheck] = useState(false);

  const AxiosInstance = AxiosHTTPClient();
  //service Api
  const storeDetailsService: StoreDetailsService = new StoreDetailsService(
    AxiosInstance
  );

  const handleDelete = (e: any) => {
    const { value, checked } = e.target;
    if (checked) {
      setChecked([...isChecked, value]);
    } else {
      setChecked(isChecked.filter((e) => e !== value));
    }
  };

  const handleSelectAll = (e: any) => {
    const { value, checked } = e.target;
    let checkedData: any = [];
    setSelectallUncheck(checked);
    if (checked)
      apiData.map((item: any) => {
        checkedData = [...checkedData, item.id];
      });
    setChecked(checkedData);
  };

  //Getdata
  const getData = async () => {
    try {
      storeDetailsService.getlist().then((res) => {
        setApiData(res.data.responce);
      });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  //delete data
  const onDelete = (id: any) => {
    var option = window.confirm(`Are you sure delete`);
    if (option) {
      storeDetailsService.deleteStoreDetails(id).then((res) => {
        getData();
        notify({ message: res.data.message, status: "success" });
      });
    }
  };

  //Deteteall
  const alldelete = async () => {
    var option = window.confirm(`Are you sure delete`);
    if (isChecked) {
      if (option) {
        storeDetailsService.deleteall({ isChecked }).then((res) => {
          getData();
          setSelectallUncheck(false);
          notify({ message: res.data.message, status: "success" });
          getData();
        });
      }
    }
  };

  //filter data
  const handlefilter = (
    eventFiltercode: any,
    eventStoreId: any,
    eventImei: any,
    eventPosId: any,
    eventDeviceNo: any,
    eventHwId: any,
    eventMerchantId: any,
    eventSecurityToken: any,
    eventMerchantName: any,
    eventStoreName: any
    // eventMerchantCode: any
  ) => {
    const data: any = {
      eventFiltercode: eventFiltercode !== null ? eventFiltercode : "",
      eventStoreId: eventStoreId !== null ? eventStoreId : "",
      eventImei: eventImei !== null ? eventImei : "",
      eventPosId: eventPosId !== null ? eventPosId : "",
      eventDeviceNo: eventDeviceNo !== null ? eventDeviceNo : "",
      eventHwId: eventHwId !== null ? eventHwId : "",
      eventMerchantId: eventMerchantId !== null ? eventMerchantId : "",
      eventSecurityToken: eventSecurityToken !== null ? eventSecurityToken : "",
      eventMerchantName: eventMerchantName !== null ? eventMerchantName : "",
      eventStoreName: eventStoreName !== null ? eventStoreName : "",
      // eventMerchantCode: eventMerchantCode !== null ? eventMerchantCode : "",
    };

    storeDetailsService.getFilterList(data).then((res) => {
      setApiData(res.data.responce);
    });
  };

  //sync data
  const sync = async () => {
    storeDetailsService.sync().then((getData) => {
      setApiData(getData.data.responce);
    });
    setFilterCode("");
    setfilterStoreId("");
    setfilterIMEI("");
    setfilterPosId("");
    setFilterDeviceNo("");
    setfilterHwId("");
    setMerchantId("");
    setfilterSecurityToken("");
    setFilterMerchantName("");
    setfilterStoreName("");
    // setFilterMerchantCode("");
    setChecked([]);
  };

  return (
    <>
      <div style={{ display: "flex" }}>
        <Sidebar />
        <div className="Paymemtform">
          <div className="horizontalline">
            <div>
              <h3>Store Details</h3>
            </div>

            <div>
              <NavLink to="/storedetailsform">
                <Button
                  className="w3-button w3-teal"
                  style={{
                    color: "white",
                    backgroundColor: "rgb(255 144 0)",
                  }}
                >
                  <AddIcon />
                  Add
                </Button>
              </NavLink>
            </div>
          </div>

          {/* filter */}
          <Card
            variant="outlined"
            style={{ padding: "10px", marginTop: "8px" }}
          >
            <div className="form_column_storedetails">
              <div style={{ fontSize: "large" }}>Filter</div>
              <div style={{ marginLeft: "5px" }}>
                <FilterAltIcon className="filterIcon" />
              </div>
            </div>
            <div className="form_column_storedetails">
              <div className="autocomplete">
                <div className="form_input">
                  <TextField
                    id="outlined-search"
                    type="search"
                    value={filterCode}
                    placeholder="Branch code"
                    style={{ width: 150, marginTop: "6px" }}
                    onChange={(event: any) => {
                      setFilterCode(event.target.value);
                      handlefilter(
                        event.target.value,
                        filterStoreId,
                        filterIMEI,
                        filterPosId,
                        filterDeviceNo,
                        filterHwId,
                        filterMerchantId,
                        filterSecurityToken,
                        filterMerchantName,
                        filterStoreName
                        // filterMerchantCode
                      );
                    }}
                  />
                </div>
                {/* <div style={{ marginLeft: "6px", marginTop: "20px" }}>
                  <FilterAltIcon className="filterIcon" />
                </div> */}
              </div>

              <div className="autocomplete">
                <div className="form_input">
                  <TextField
                    id="outlined-search"
                    type="search"
                    value={filterStoreId}
                    placeholder="Merchant Store POS Code"
                    style={{ width: 150, marginTop: "6px" }}
                    onChange={(event: any) => {
                      setfilterStoreId(event.target.value);
                      handlefilter(
                        filterCode,
                        event.target.value,
                        filterIMEI,
                        filterPosId,
                        filterDeviceNo,
                        filterHwId,
                        filterMerchantId,
                        filterSecurityToken,
                        filterMerchantName,
                        filterStoreName
                        // filterMerchantCode
                      );
                    }}
                  />
                </div>

                {/* <div style={{ marginLeft: "6px", marginTop: "20px" }}>
                  <FilterAltIcon className="filterIcon" />
                </div> */}
              </div>
              <div className="autocomplete">
                <div className="form_input">
                  <TextField
                    id="outlined-search"
                    type="search"
                    value={filterIMEI}
                    placeholder="IMEI"
                    style={{ width: 150, marginTop: "6px" }}
                    onChange={(event: any) => {
                      setfilterIMEI(event.target.value);
                      handlefilter(
                        filterCode,
                        filterStoreId,
                        event.target.value,
                        filterPosId,
                        filterDeviceNo,
                        filterHwId,
                        filterMerchantId,
                        filterSecurityToken,
                        filterMerchantName,
                        filterStoreName
                        // filterMerchantCode
                      );
                    }}
                  />
                </div>

                {/* <div style={{ marginLeft: "6px", marginTop: "20px" }}>
                  <FilterAltIcon className="filterIcon" />
                </div> */}
              </div>

              <div className="autocomplete">
                <div className="form_input">
                  <TextField
                    id="outlined-search"
                    type="search"
                    value={filterPosId}
                    placeholder="POS ID"
                    style={{ width: 150, marginTop: "6px" }}
                    onChange={(event: any) => {
                      setfilterPosId(event.target.value);
                      handlefilter(
                        filterCode,
                        filterStoreId,
                        filterIMEI,
                        event.target.value,
                        filterDeviceNo,
                        filterHwId,
                        filterMerchantId,
                        filterSecurityToken,
                        filterMerchantName,
                        filterStoreName
                        // filterMerchantCode
                      );
                    }}
                  />
                </div>

                {/* <div style={{ marginLeft: "6px", marginTop: "20px" }}>
                  <FilterAltIcon className="filterIcon" />
                </div> */}
              </div>
              <div className="autocomplete">
                <div className="form_input">
                  <TextField
                    id="outlined-search"
                    type="search"
                    value={filterDeviceNo}
                    placeholder="Device No"
                    style={{ width: 150, marginTop: "6px" }}
                    onChange={(event: any) => {
                      setFilterDeviceNo(event.target.value);
                      handlefilter(
                        filterCode,
                        filterStoreId,
                        filterIMEI,
                        filterPosId,
                        event.target.value,
                        filterHwId,
                        filterMerchantId,
                        filterSecurityToken,
                        filterMerchantName,
                        filterStoreName
                        // filterMerchantCode
                      );
                    }}
                  />
                </div>

                {/* <div style={{ marginLeft: "6px", marginTop: "20px" }}>
                  <FilterAltIcon className="filterIcon" />
                </div> */}
              </div>

              <div className="autocomplete">
                <div className="form_input">
                  <TextField
                    id="outlined-search"
                    type="search"
                    value={filterHwId}
                    placeholder="H/W ID"
                    style={{ width: 150, marginTop: "6px" }}
                    onChange={(event: any) => {
                      setfilterHwId(event.target.value);
                      handlefilter(
                        filterCode,
                        filterStoreId,
                        filterIMEI,
                        filterPosId,
                        filterDeviceNo,
                        event.target.value,
                        filterMerchantId,
                        filterSecurityToken,
                        filterMerchantName,
                        filterStoreName
                        // filterMerchantCode
                      );
                    }}
                  />
                </div>

                {/* <div style={{ marginLeft: "6px", marginTop: "20px" }}>
                  <FilterAltIcon className="filterIcon" />
                </div> */}
              </div>
              <div className="autocomplete">
                <div className="form_input">
                  <TextField
                    id="outlined-search"
                    type="search"
                    value={filterMerchantId}
                    placeholder="Merchant ID"
                    style={{ width: 150, marginTop: "6px" }}
                    onChange={(event: any) => {
                      setMerchantId(event.target.value);
                      handlefilter(
                        filterCode,
                        filterStoreId,
                        filterIMEI,
                        filterPosId,
                        filterDeviceNo,
                        filterHwId,
                        event.target.value,
                        filterSecurityToken,
                        filterMerchantName,
                        filterStoreName
                        // filterMerchantCode
                      );
                    }}
                  />
                </div>

                {/* <div style={{ marginLeft: "6px", marginTop: "20px" }}>
                  <FilterAltIcon className="filterIcon" />
                </div> */}
              </div>

              <div className="autocomplete">
                <div className="form_input">
                  <TextField
                    id="outlined-search"
                    type="search"
                    value={filterSecurityToken}
                    placeholder="Security Token No"
                    style={{ width: 150, marginTop: "6px" }}
                    onChange={(event: any) => {
                      setfilterSecurityToken(event.target.value);
                      handlefilter(
                        filterCode,
                        filterStoreId,
                        filterIMEI,
                        filterPosId,
                        filterDeviceNo,
                        filterHwId,
                        filterMerchantId,
                        event.target.value,
                        filterMerchantName,
                        filterStoreName
                        // filterMerchantCode
                      );
                    }}
                  />
                </div>

                {/* <div style={{ marginLeft: "6px", marginTop: "20px" }}>
                  <FilterAltIcon className="filterIcon" />
                </div> */}
              </div>
              <div className="autocomplete">
                <div className="form_input">
                  <TextField
                    id="outlined-search"
                    type="search"
                    value={filterMerchantName}
                    placeholder="Merchant Name"
                    style={{ width: 150, marginTop: "6px" }}
                    onChange={(event: any) => {
                      setFilterMerchantName(event.target.value);
                      handlefilter(
                        filterCode,
                        filterStoreId,
                        filterIMEI,
                        filterPosId,
                        filterDeviceNo,
                        filterHwId,
                        filterMerchantId,
                        filterSecurityToken,
                        event.target.value,
                        filterStoreName
                        // filterMerchantCode
                      );
                    }}
                  />
                </div>

                {/* <div style={{ marginLeft: "6px", marginTop: "20px" }}>
                  <FilterAltIcon className="filterIcon" />
                </div> */}
              </div>

              <div className="autocomplete">
                <div className="form_input">
                  <TextField
                    id="outlined-search"
                    type="search"
                    value={filterStoreName}
                    placeholder="Store Name"
                    style={{ width: 150, marginTop: "6px" }}
                    onChange={(event: any) => {
                      setfilterStoreName(event.target.value);
                      handlefilter(
                        filterCode,
                        filterStoreId,
                        filterIMEI,
                        filterPosId,
                        filterDeviceNo,
                        filterHwId,
                        filterMerchantId,
                        filterSecurityToken,
                        filterMerchantName,
                        event.target.value
                        // filterMerchantCode
                      );
                    }}
                  />
                </div>

                {/* <div style={{ marginLeft: "6px", marginTop: "20px" }}>
                  <FilterAltIcon className="filterIcon" />
                </div> */}
              </div>
              {/* <div className="autocomplete">
                <div className="form_input">
                  <TextField
                    id="outlined-search"
                    type="search"
                    value={filterMerchantCode}
                    placeholder="Merchant Store POS Code"
                    style={{ width: 150, marginTop: "6px" }}
                    onChange={(event: any) => {
                      setFilterMerchantCode(event.target.value);
                      handlefilter(
                        filterCode,
                        filterStoreId,
                        filterIMEI,
                        filterPosId,
                        filterDeviceNo,
                        filterHwId,
                        filterMerchantId,
                        filterSecurityToken,
                        filterMerchantName,
                        filterStoreName,
                        event.target.value
                      );
                    }}
                  />
                </div>

                <div style={{ marginLeft: "6px", marginTop: "20px" }}>
                  <FilterAltIcon className="filterIcon" />
                </div>
              </div> */}
            </div>
          </Card>

          <div className="sync_button">
            {apiData.length !== 0 ? (
              <div>
                {isChecked.length > 1 && (
                  <Button
                    style={{
                      color: "white",
                      backgroundColor: "rgb(255 144 0)",
                    }}
                    onClick={alldelete}
                  >
                    <DeleteSweepIcon /> Delete All
                  </Button>
                )}
              </div>
            ) : (
              <div></div>
            )}
            <div style={{ marginBottom: "10px" }}>
              <p>Reset Filter</p>
              <Button
                style={{ color: "white", backgroundColor: "rgb(255 144 0)" }}
                onClick={sync}
              >
                <RefreshIcon />
              </Button>
            </div>
          </div>

          <TableContainer>
            <Table celled>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell
                    style={{ width: "3%", textAlign: "center" }}
                  >
                    <input
                      type="checkbox"
                      checked={selectallUncheck}
                      onChange={(e) => handleSelectAll(e)}
                    />
                  </Table.HeaderCell>

                  <Table.HeaderCell style={{ textAlign: "center" }}>
                    Branch code
                  </Table.HeaderCell>
                  <Table.HeaderCell style={{ textAlign: "center" }}>
                    Merchant Store POS Code
                  </Table.HeaderCell>

                  <Table.HeaderCell style={{ textAlign: "center" }}>
                    IMEI
                  </Table.HeaderCell>

                  <Table.HeaderCell style={{ textAlign: "center" }}>
                    POS ID
                  </Table.HeaderCell>

                  <Table.HeaderCell style={{ textAlign: "center" }}>
                    Device No
                  </Table.HeaderCell>

                  <Table.HeaderCell style={{ textAlign: "center" }}>
                    H/W ID
                  </Table.HeaderCell>

                  <Table.HeaderCell style={{ textAlign: "center" }}>
                    Merchant ID
                  </Table.HeaderCell>

                  <Table.HeaderCell style={{ textAlign: "center" }}>
                    Security Token No
                  </Table.HeaderCell>
                  <Table.HeaderCell style={{ textAlign: "center" }}>
                    Merchant Name
                  </Table.HeaderCell>
                  <Table.HeaderCell style={{ textAlign: "center" }}>
                    Store Name
                  </Table.HeaderCell>
                  {/* <Table.HeaderCell style={{ textAlign: "center" }}>
                    Merchant Store POS Code
                  </Table.HeaderCell> */}
                  <Table.HeaderCell
                    style={{ width: "12%", textAlign: "center" }}
                    colSpan={2}
                  >
                    Action
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {apiData
                  .slice(page * perPage, (page + 1) * perPage)
                  .map((data: any, index: any) => {
                    return (
                      <Table.Row key={index}>
                        <Table.Cell style={{ textAlign: "center" }}>
                          <input
                            type="checkbox"
                            value={data.id}
                            checked={isChecked.find(
                              (item: any) => item === data.id
                            )}
                            onChange={(e) => handleDelete(e)}
                          />
                        </Table.Cell>

                        <Table.Cell>{data.code}</Table.Cell>
                        <Table.Cell>{data.store_id}</Table.Cell>
                        <Table.Cell>{data.imei}</Table.Cell>
                        <Table.Cell>{data.pos_id}</Table.Cell>
                        <Table.Cell>{data.device_no}</Table.Cell>
                        <Table.Cell>{data.hw_id}</Table.Cell>
                        <Table.Cell>{data.merchant_id}</Table.Cell>
                        <Table.Cell>{data.security_token}</Table.Cell>
                        <Table.Cell>{data.merchant_name}</Table.Cell>
                        <Table.Cell>{data.store_name}</Table.Cell>
                        {/* <Table.Cell>{data.merchant_code}</Table.Cell> */}
                        <Table.Cell style={{ textAlign: "center" }}>
                          <Link to={`/storeDetailsupdate/${data.id}`}>
                            <Button
                              style={{
                                color: "white",
                                backgroundColor: "rgb(255 144 0)",
                              }}
                            >
                              <EditIcon />
                            </Button>
                          </Link>
                        </Table.Cell>
                        <Table.Cell style={{ textAlign: "center" }}>
                          <Button
                            className="flex "
                            style={{
                              color: "white",
                              backgroundColor: "rgb(255 144 0)",
                            }}
                            onClick={() => onDelete(data.id)}
                          >
                            <DeleteIcon />
                          </Button>
                        </Table.Cell>
                      </Table.Row>
                    );
                  })}
              </Table.Body>
            </Table>
          </TableContainer>
          {apiData.length > 0 ? (
            <TablePagination
              component="div"
              count={apiData.length}
              page={page}
              onPageChange={(e, newPage) => setPage(newPage)}
              rowsPerPage={perPage}
              rowsPerPageOptions={[]}
            />
          ) : (
            <div>No data found</div>
          )}
        </div>
      </div>
    </>
  );
};

export default StoreDetails;