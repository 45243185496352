import React, { useEffect, useState, useRef } from "react";
import { Table, Button } from "semantic-ui-react";
import Sidebar from "../Sidebar";
import { TablePagination } from "@mui/material";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@mui/material/TextField";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { AxiosHTTPClient } from "../../util/AxiosInstance";
import { PaymentHistoryService } from "../../service/PaymentHistoryService";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { useNotifications } from "reapop";
import RefreshIcon from "@mui/icons-material/Refresh";
import { styled } from "@mui/material/styles";

let style = {
  marginTop: "15px",
  minWidth: "400px",
};

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export default function PaymentTypesRead() {
  const [apiData, setApiData] = useState([]);
  const [listMapping, setListMapping] = useState([]);
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [transaction_id, setTransactionId] = useState("");
  const [first_name, setFirstName] = useState("");
  const [email, setEmail] = useState("");
  const [pg_name, setpgName] = useState("");
  const [pg_status, setpgStatus] = useState("");
  const [payment_mode, setPaymentMode] = useState("");
  const [amount, setAmount] = useState("");
  const [created_date_time, setCreatedDateTime] = useState("");
  const AxiosInstance = AxiosHTTPClient();
  const [open, setOpen] = useState(false);
  const descriptionElementRef = useRef<HTMLElement>(null);
  const { notify } = useNotifications();
  const [notype, setNoType] = useState(false);
  const [paymentHistory, setPaymentHistory] = useState<any>();

  //service Api
  const paymentHistoryService: PaymentHistoryService =
    new PaymentHistoryService(AxiosInstance);

  const defaultLabelDisplayedRows = (props: any) => {
    return `${props.page + 1} of ${
      props.count !== -1 ? props.count : `more than ${props.to}`
    }`;
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    handlefilter(
      transaction_id,
      first_name,
      email,
      pg_name,
      pg_status,
      payment_mode,
      amount,
      created_date_time
    );
    setPage(newPage);
  };

  //get data
  const getData = () => {
    paymentHistoryService.getlist(page + 1, rowsPerPage).then((getData) => {
      setApiData(getData.data.response);
      setTotalPages(getData.data.totalPages);
      notify({ message: getData.data.message, status: "success" });
      setTransactionId("");
      setFirstName("");
      setEmail("");
      setpgName("");
      setpgStatus("");
      setPaymentMode("");
      setAmount("");
      setPage(0);
    });
  };

  const getlistmapping = () => {
    paymentHistoryService.getlistmapping().then((getlistmapping) => {
      setListMapping(getlistmapping.data.response);
    });
  };

  useEffect(() => {
    getData();
    getlistmapping();
  }, [rowsPerPage]);

  const handlefilter = (
    eventTransactionId: any,
    eventFirstName: any,
    eventEmail: any,
    eventpgName: any,
    eventpgStatus: any,
    eventPaymentMode: any,
    eventAmount: any,
    eventCreatedDateTime: any
  ) => {
    const data: any = {
      eventTransactionId: eventTransactionId,
      eventFirstName: eventFirstName,
      eventEmail: eventEmail,
      eventpgName: eventpgName !== null ? eventpgName : "",
      eventpgStatus: eventpgStatus !== null || undefined ? eventpgStatus : "",
      eventPaymentMode: eventPaymentMode !== null ? eventPaymentMode : "",
      eventAmount: eventAmount,
      eventCreatedDateTime: eventCreatedDateTime,
    };

    paymentHistoryService
      .getFilterList(data, page + 1, rowsPerPage)
      .then((getData) => {
        if (getData.data.status === true) {
          setApiData(getData.data.response);
          setTotalPages(getData.data.totalPages);
        }
      })
      .catch((err) => {
        setApiData([]);
        // setTimeout(() => {
        setNoType(true);
        // }, 1000);
        notify({ message: "No Records found", status: "error" });
      });
  };

  //Popup start
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const loadOptionPgName = () => {
    const pgNamekey = listMapping.reduce((group: any, product: any) => {
      const { pg_name } = product;
      group[pg_name] = group[pg_name] ?? [];

      group[pg_name].push(product);

      return group;
    }, {});

    return Object.keys(pgNamekey);
  };

  const loadOptionPgstatus = () => {
    const pgStatuskey = listMapping.reduce((group: any, product: any) => {
      const { pg_status } = product;
      group[pg_status] = group[pg_status] ?? [];

      group[pg_status].push(product);

      return group;
    }, {});

    return Object.keys(pgStatuskey);
  };

  const loadOptionPvkStatus = () => {
    const pvkStatuskey = listMapping.reduce((group: any, product: any) => {
      const { payment_mode } = product;
      group[payment_mode] = group[payment_mode] ?? [];

      group[payment_mode].push(product);

      return group;
    }, {});

    return Object.keys(pvkStatuskey);
  };

  return (
    <>
      <div style={{ display: "flex" }}>
        <Sidebar />
        <div className="Paymemtform">
          <div className="table-wrp">
            <div className="horizontalline">
              <h3>Payment History</h3>

              <Button
                style={{
                  color: "white",
                  backgroundColor: "rgb(255 144 0)",
                  marginLeft: "auto",
                }}
                onClick={getData}
              >
                <RefreshIcon />
              </Button>
            </div>

            <Table celled style={style}>
              <Table.Header>
                <Table.Row>
                  {/* <Table.HeaderCell style={{ textAlign: "center" }}>
                    Id
                  </Table.HeaderCell> */}
                  <Table.HeaderCell style={{ textAlign: "center" }}>
                    <TextField
                      id="outlined-search"
                      type="search"
                      value={transaction_id}
                      placeholder="Transaction Id"
                      style={{ width: 150, marginTop: "6px" }}
                      // disabled={!!notype && true}
                      onChange={(event: any) => {
                        if (event.target.value == "") {
                          setPage(0);
                          setTransactionId("");
                          handlefilter(
                            "",
                            first_name,
                            email,
                            pg_name,
                            pg_status,
                            payment_mode,
                            amount,
                            created_date_time
                          );
                        } else {
                          setTransactionId(event.target.value);
                          handlefilter(
                            event.target.value,
                            first_name,
                            email,
                            pg_name,
                            pg_status,
                            payment_mode,
                            amount,
                            created_date_time
                          );
                        }
                      }}
                    />
                  </Table.HeaderCell>
                  <Table.HeaderCell style={{ textAlign: "center" }}>
                    <TextField
                      id="outlined-search"
                      type="search"
                      value={first_name}
                      style={{ width: 150, marginTop: "6px" }}
                      placeholder="First Name"
                      onChange={(event: any) => {
                        if (event.target.value == "") {
                          setPage(0);
                          setFirstName("");
                          handlefilter(
                            transaction_id,
                            "",
                            email,
                            pg_name,
                            pg_status,
                            payment_mode,
                            amount,
                            created_date_time
                          );
                        } else {
                          setFirstName(event.target.value);
                          handlefilter(
                            transaction_id,
                            event.target.value,
                            email,
                            pg_name,
                            pg_status,
                            payment_mode,
                            amount,
                            created_date_time
                          );
                        }
                      }}
                    />
                  </Table.HeaderCell>

                  <Table.HeaderCell style={{ textAlign: "center" }}>
                    <TextField
                      id="outlined-search"
                      type="search"
                      value={email}
                      style={{ width: 150, marginTop: "6px" }}
                      placeholder="Email"
                      onChange={(event: any) => {
                        if (event.target.value == "") {
                          setPage(0);
                          setEmail("");
                          handlefilter(
                            transaction_id,
                            first_name,
                            "",
                            pg_name,
                            pg_status,
                            payment_mode,
                            amount,
                            created_date_time
                          );
                        } else {
                          setEmail(event.target.value);
                          handlefilter(
                            transaction_id,
                            first_name,
                            event.target.value,
                            pg_name,
                            pg_status,
                            payment_mode,
                            amount,
                            created_date_time
                          );
                        }
                      }}
                    />
                  </Table.HeaderCell>

                  <Table.HeaderCell style={{ textAlign: "center" }}>
                    <div className="autocomplete">
                      <Autocomplete
                        options={loadOptionPgName()}
                        style={{ width: 150, marginTop: "6px" }}
                        renderInput={(params) => (
                          <TextField
                            placeholder="PgName"
                            {...params}
                            variant="outlined"
                          />
                        )}
                        value={pg_name}
                        onChange={(event: any, value: any) => {
                          if (value == null) {
                            setPage(0);
                            setpgName("");
                            handlefilter(
                              transaction_id,
                              first_name,
                              email,
                              "",
                              pg_status,
                              payment_mode,
                              amount,
                              created_date_time
                            );
                          } else {
                            setpgName(value);
                            handlefilter(
                              transaction_id,
                              first_name,
                              email,
                              value,
                              pg_status,
                              payment_mode,
                              amount,
                              created_date_time
                            );
                          }
                        }}
                      />
                    </div>
                  </Table.HeaderCell>

                  <Table.HeaderCell style={{ textAlign: "center" }}>
                    <div className="autocomplete">
                      <Autocomplete
                        options={loadOptionPgstatus()}
                        style={{ width: 150, marginTop: "6px" }}
                        renderInput={(params) => (
                          <TextField
                            placeholder="PgStatus"
                            {...params}
                            variant="outlined"
                          />
                        )}
                        value={pg_status}
                        onChange={(event: any, value: any) => {
                          if (value == null) {
                            setPage(0);
                            setpgStatus("");
                            handlefilter(
                              transaction_id,
                              first_name,
                              email,
                              pg_name,
                              "",
                              payment_mode,
                              amount,
                              created_date_time
                            );
                          } else {
                            setpgStatus(value);
                            handlefilter(
                              transaction_id,
                              first_name,
                              email,
                              pg_name,
                              value,
                              payment_mode,
                              amount,
                              created_date_time
                            );
                          }
                        }}
                      />
                    </div>
                  </Table.HeaderCell>

                  <Table.HeaderCell style={{ textAlign: "center" }}>
                    <div className="autocomplete">
                      <Autocomplete
                        options={loadOptionPvkStatus()}
                        style={{ width: 150, marginTop: "6px" }}
                        renderInput={(params) => (
                          <TextField
                            placeholder="Mode"
                            {...params}
                            variant="outlined"
                          />
                        )}
                        value={payment_mode}
                        onChange={(event: any, value: any) => {
                          if (value == null) {
                            setPage(0);
                            setPaymentMode("");
                            handlefilter(
                              transaction_id,
                              first_name,
                              email,
                              pg_name,
                              pg_status,
                              "",
                              amount,
                              created_date_time
                            );
                          } else {
                            setPaymentMode(value);
                            handlefilter(
                              transaction_id,
                              first_name,
                              email,
                              pg_name,
                              pg_status,
                              value,
                              amount,
                              created_date_time
                            );
                          }
                        }}
                      />
                    </div>
                  </Table.HeaderCell>

                  <Table.HeaderCell style={{ textAlign: "center" }}>
                    <TextField
                      id="outlined-search"
                      type="search"
                      value={amount}
                      placeholder="Amount"
                      style={{ width: 150, marginTop: "6px" }}
                      onChange={(event: any) => {
                        setAmount(event.target.value);
                        handlefilter(
                          transaction_id,
                          first_name,
                          email,
                          pg_name,
                          pg_status,
                          payment_mode,
                          event.target.value,
                          created_date_time
                        );
                      }}
                    />
                  </Table.HeaderCell>

                  <Table.HeaderCell style={{ textAlign: "center" }}>
                    Created date
                  </Table.HeaderCell>
                  <Table.HeaderCell style={{ textAlign: "center" }}>
                    More Info
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {apiData.map((data: any, index: any) => {
                  return (
                    <Table.Row key={index}>
                      {/* <Table.Cell style={{ textAlign: "center" }}>
                        {data.id}
                      </Table.Cell> */}
                      <Table.Cell style={{ textAlign: "center" }}>
                        {data.transaction_id}
                      </Table.Cell>
                      <Table.Cell style={{ textAlign: "center" }}>
                        {data.first_name}
                      </Table.Cell>
                      <Table.Cell style={{ textAlign: "center" }}>
                        {data.email}
                      </Table.Cell>
                      <Table.Cell style={{ textAlign: "center" }}>
                        {data.pg_name}
                      </Table.Cell>
                      <Table.Cell style={{ textAlign: "center" }}>
                        {data.pg_status}
                      </Table.Cell>
                      <Table.Cell style={{ textAlign: "center" }}>
                        {data.payment_mode}
                      </Table.Cell>
                      <Table.Cell style={{ textAlign: "center" }}>
                        {data.amount}
                      </Table.Cell>
                      <Table.Cell style={{ textAlign: "center" }}>
                        {data.created_date_time}
                      </Table.Cell>
                      <Table.Cell style={{ textAlign: "center" }}>
                        <VisibilityIcon
                          color="primary"
                          onClick={() => {
                            setPaymentHistory(apiData[index]);

                            handleClickOpen();
                          }}
                        />
                      </Table.Cell>
                    </Table.Row>
                  );
                })}
              </Table.Body>
            </Table>
          </div>

          {open && (
            <BootstrapDialog
              onClose={handleClose}
              aria-labelledby="customized-dialog-title"
              open={open}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    Payment History Details
                  </DialogTitle>
                </div>
                <div>
                  <IconButton
                    aria-label="close"
                    onClick={handleClose}
                  >
                    <CloseIcon style={{ marginTop: "10px" }} />
                  </IconButton>
                </div>
              </div>
              <DialogContent dividers>
                <Typography gutterBottom>
                  <></>
                  Transaction Id :<span> {paymentHistory.transaction_id}</span>
                </Typography>
                <Typography gutterBottom>
                  Payment Gateway Transaction Id :
                  <span>{paymentHistory.pg_transaction_id}</span>
                </Typography>
                <Typography gutterBottom>
                  Payment Gateway Name : <span>{paymentHistory.pg_name}</span>
                </Typography>
                <Typography gutterBottom>
                  Payment Gateway Code : <span>{paymentHistory.pg_code}</span>
                </Typography>
                <Typography gutterBottom>
                  Payment Mode : <span>{paymentHistory.payment_mode}</span>
                </Typography>
                <Typography gutterBottom>
                  Amount : <span>{paymentHistory.amount}</span>
                </Typography>
                <Typography gutterBottom>
                  Product Info : <span>{paymentHistory.product_info}</span>
                </Typography>
                <Typography gutterBottom>
                  First Name : <span>{paymentHistory.first_name}</span>
                </Typography>
                <Typography gutterBottom>
                  Last Name : <span>{paymentHistory.last_name}</span>
                </Typography>
                <Typography gutterBottom>
                  Email : <span>{paymentHistory.email}</span>
                </Typography>
                <Typography gutterBottom>
                  Payment Gateway Status :{" "}
                  <span>{paymentHistory.pg_status}</span>
                </Typography>
                <Typography gutterBottom>
                  Payment Gateway Error Code :{" "}
                  <span>{paymentHistory.pg_error_code}</span>
                </Typography>
                <Typography gutterBottom>
                  Payment Gateway Error Description :
                  <span>{paymentHistory.pg_error_description}</span>
                </Typography>
                <Typography gutterBottom>
                  Poorvika Status : <span>{paymentHistory.pvk_status}</span>
                </Typography>
                <Typography gutterBottom>
                  Poorvika Error Code :
                  <span>{paymentHistory.pvk_error_code}</span>
                </Typography>
                <Typography gutterBottom>
                  Reconciled Through :
                  <span>{paymentHistory.reconciled_through}</span>
                </Typography>
                <Typography gutterBottom>
                  Created Date Time :
                  <span>{paymentHistory.created_date_time}</span>
                </Typography>
              </DialogContent>
              <DialogActions>
                <Button autoFocus onClick={handleClose}>
                  Exit
                </Button>
              </DialogActions>
            </BootstrapDialog>
          )}

          <TablePagination
            backIconButtonProps={
              page === 0 ? { disabled: true } : { disabled: false }
            }
            nextIconButtonProps={
              page + 1 === totalPages || totalPages === 0
                ? { disabled: true }
                : { disabled: false }
            }
            component="div"
            count={totalPages}
            labelDisplayedRows={defaultLabelDisplayedRows}
            rowsPerPageOptions={[5, 10, 15, 20, 25, 30, 40, 50]}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      </div>
    </>
  );
}
