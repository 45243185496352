import React, { useContext } from "react";
import { useSessionStorage } from "react-use";

interface AuthenticationProps {
  isAuthenticated?: boolean;
  principal?: any;
  login?: any;
  logout?: any;
}

const defaultValues = {
  isAuthenticated: false,
  principal: {},
  login: () => {},
  logout: () => {},
};

const AUTH_STORAGE_KEY = "authentication";

const AuthenticationContext =
  React.createContext<Partial<AuthenticationProps>>(defaultValues);
export const AuthContextProvider = AuthenticationContext.Provider;

export const AuthenticationProvider = ({ children }: any) => {
  const [authentication, setAuthentication] = useSessionStorage(
    AUTH_STORAGE_KEY,
    defaultValues
  );
  console.log(authentication);

  const login = async (principal: any) =>
    await setAuthentication({
      ...authentication,
      isAuthenticated: true,
      principal: principal,
    });

  const logout = () => {
    setAuthentication(defaultValues);
  };

  const values = { ...authentication, login, logout };

  return <AuthContextProvider value={values}>{children}</AuthContextProvider>;
};

export function useAuthentication() {
  return useContext(AuthenticationContext);
}
