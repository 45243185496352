import React, { useState, useEffect } from "react";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import "tailwindcss/tailwind.css";
import { Button } from "semantic-ui-react";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteSweepIcon from "@mui/icons-material/DeleteSweep";
import SyncIcon from "@mui/icons-material/Sync";
import { NavLink } from "react-router-dom";
import CancelIcon from "@mui/icons-material/Cancel";
import { AxiosHTTPClient } from "../../util/AxiosInstance";
import { PaymentCsvService } from "../../service/PaymentCsvService";

const useStyles = makeStyles({
  table: {
    minWidth: 1000,
  },
});

let style = {
  fontSize: "15px",
  fontWeight: "600",
};

export default function CsvTable() {
  const classes = useStyles();
  const [selectedFile, setSelectedFile] = useState("");
  const [isSelected, setIsSelected] = useState(false);
  //getcsv datas
  const [csvTable, setCsvTable] = useState([]);
  const [success, setSuccess] = useState("");
  const AxiosInstance = AxiosHTTPClient();

  //service Api
  const paymentCsvService: PaymentCsvService = new PaymentCsvService(
    AxiosInstance
  );

  const changeHandler = (event: any) => {
    setSelectedFile(event.target.files[0]);
    setIsSelected(true);
  };

  const changecancel = (event: any) => {
    setIsSelected(false);
    (document.getElementById("csvrefresh") as HTMLInputElement).value = "";
  };

  const handleSubmission = (e: any) => {
    const formData = new FormData();
    if (selectedFile != null) {
      formData.append("csv", selectedFile);
      paymentCsvService
        .paymentAdd(formData)
        .then((res: any) => {
          getcsv();
          if (res.status === 200) {
            setSuccess(res.data.message);
          }
          //(document.getElementById("csvrefresh") as HTMLInputElement).value == "";
          setIsSelected(false);
          if (!res.ok) {
            return Promise.reject(res);
          }
          return res.json();
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };
  function getcsv() {
    paymentCsvService.getlist().then((res) => {
      setCsvTable(res.data.data);
    });
  }

  useEffect(() => {
    getcsv();
  }, []);

  //Delete data
  function handleDetete(id: any) {
    var option = window.confirm(`Are you sure delete`);
    if (option) {
      paymentCsvService.deletePayment(id).then((res: any) => {
        if (res !== "") {
          setSuccess(res.data.message);
        }
        getcsv();
      });
    }
  }
  //Delete all data
  function handleallDetete() {
    var option = window.confirm(`Are you sure delete all data`);
    if (option) {
      paymentCsvService.deleteall().then((res: any) => {
        if (res !== "") {
          setSuccess(res.data.message);
        }
        getcsv();
      });
    }
  }
  //Delete all expired data
  function deleteexpired() {
    paymentCsvService.deleteExpired().then((res: any) => {
      if (res !== "") {
        setSuccess(res.data.message);
      }
      getcsv();
    });
  }
  return (
    <div>
      <div>
        <input
          type="file"
          name="csv"
          onChange={changeHandler}
          accept=".csv"
          className="filedesign"
          id="csvrefresh"
        />

        {isSelected && (
          <div>
            <NavLink to="/fileupload">
              <Button className="w3-button w3-teal" onClick={changecancel}>
                <CancelIcon />
                Cancel
              </Button>
            </NavLink>
            <Button
              style={{
                color: "white",
                backgroundColor: "rgb(255 144 0)",
              }}
              type="submit"
              onClick={handleSubmission}
            >
              Submit
            </Button>
          </div>
        )}
      </div>

      {success ? <div style={{ color: "green" }}>{success}</div> : ""}

      <TableContainer component={Paper}>
        <Table
          size="small"
          aria-label="a dense table"
          className={classes.table}
        >
          <TableHead>
            <TableRow>
              <TableCell style={style}>Product id</TableCell>
              <TableCell style={style} align="center">
                SKU code
              </TableCell>
              <TableCell style={style} align="center">
                Offer description
              </TableCell>
              <TableCell style={style} align="center">
                EMI tenure
              </TableCell>
              <TableCell style={style} align="center">
                Debit cards
              </TableCell>
              <TableCell style={style} align="center">
                Credit cards
              </TableCell>
              <TableCell style={style} align="center">
                Eligible product
              </TableCell>
              <TableCell style={style} align="center">
                Start date
              </TableCell>
              <TableCell style={style} align="center">
                End date
              </TableCell>
              <TableCell style={style} align="center">
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {csvTable &&
              csvTable.map((item: any, index: any) => (
                <TableRow
                  key={index}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {item.Productid}
                  </TableCell>
                  <TableCell align="center">{item.SQIcode}</TableCell>
                  <TableCell align="center">{item.Offerdescription}</TableCell>
                  <TableCell align="center">{item.EMItenure}</TableCell>
                  <TableCell align="center">{item.Depitcards}</TableCell>
                  <TableCell align="center">{item.Creditcards}</TableCell>
                  <TableCell align="center">{item.Eligibleproduct}</TableCell>
                  <TableCell align="center">
                    {moment(item.Startdate).format("DD-MM-YYYY")}
                  </TableCell>
                  <TableCell align="center">
                    {moment(item.Enddate).format("DD-MM-YYYY")}
                  </TableCell>
                  <TableCell align="center">
                    <Button
                      fontSize="small"
                      style={{
                        color: "white",
                        backgroundColor: "rgb(255 144 0)",
                      }}
                      onClick={() => handleDetete(item.Productid)}
                    >
                      <DeleteIcon />
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            {csvTable ? (
              <div>
                <Button
                  style={{
                    color: "white",
                    backgroundColor: "rgb(255 144 0)",
                  }}
                  onClick={handleallDetete}
                >
                  <DeleteSweepIcon />
                  Detele all
                </Button>
                <Button
                  style={{ color: "white", backgroundColor: "rgb(255 144 0)" }}
                  onClick={deleteexpired}
                >
                  <SyncIcon />
                </Button>
              </div>
            ) : (
              <div>No data found</div>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
