import React from "react";
import CsvTable from "./Table";
import Sidebar from "../Sidebar";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
export default function Tableform() {
  return (
    <>
      <div style={{ display: "flex" }}>
        <Sidebar />

        <div className="paymentform" style={{ margin: "50px" }}>
          <h3
            style={{
              fontSize: "20px",
              borderBottom: "2px solid #ff752e63",
              fontWeight: "600",
            }}
          >
            PinePG SKU Details
          </h3>
          <br />
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <h2
              style={{
                fontSize: "18px",
                fontWeight: "600",
                marginBottom: "10px",
                marginLeft: "0px",
              }}
            >
              Upload PinePG SKU File{" "}
            </h2>

            <div>
              <a
                style={{ color: "#0fa5e2" }}
                href={process.env.PUBLIC_URL + "/csv/sample.csv"}
                download
              >
                <CloudDownloadIcon style={{ color: "#0fa5e2" }} />
                Sample CSV
              </a>
            </div>
          </div>
          <CsvTable />
        </div>
      </div>
    </>
  );
}
