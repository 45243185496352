import React, { useState } from "react";
import { useAuthentication } from "../util/Authentication";
import { AppBar, Toolbar, Box, Tabs, Tab } from "@mui/material";
import { useNavigate } from "react-router-dom";
import LogoutIcon from "@mui/icons-material/Logout";
import { useKeycloak } from "@react-keycloak/web";

type HeaderProps = {
  isAuthenticated?: any;
};

const Header: React.FC<HeaderProps> = () => {
  let styles = {
    marginTop: "9px",
  };
  const navigate = useNavigate();
  const [value, setvalue] = useState();
  const { isAuthenticated, logout } = useAuthentication();
  const { keycloak, initialized } = useKeycloak();
  const PoorvikaHeader = require("../asset/PoorvikaHeader.png");

  const handlelogout = () => {
    if (keycloak?.authenticated) {
      keycloak.clearToken();
      keycloak.logout();
      logout();
      sessionStorage.clear();
      navigate("/login");
    } else {
      logout();
      sessionStorage.clear();
      navigate("/login");
    }
  };

  return (
    <div>
      <AppBar
        position="sticky"
        style={{
          background: "#fff",
          boxShadow: "none",
          borderBottom: "1px solid #ff752e52",
        }}
      >
        <Toolbar>
          <a href="/payment_name">
            <img src={PoorvikaHeader} alt="poorvika" style={{ width: 200 }} />
          </a>
          <Box sx={{ marginLeft: "auto" }}>
            <Tabs
              onChange={(e, val) => setvalue(val)}
              value={value}
              indicatorColor="secondary"
              textColor="inherit"
            >
              {/* {isAuthenticated === false && (
                <> */}
              {/* <Tab component={Link} to="/login" label="Login" /> */}
              {/* <Tab component={Link} to="/signup" label="SignUp" />
                </>
              )
              } */}
              {isAuthenticated === true && (
                <>
                  <div className="logout" style={styles}>
                    <LogoutIcon fontSize="small" />
                  </div>
                  <Tab
                    onClick={handlelogout}
                    label="Logout"
                    style={{ color: "rgb(255 117 46)", fontWeight: "bold" }}
                  ></Tab>
                </>
              )}
            </Tabs>
          </Box>
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default Header;
