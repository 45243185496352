import axios from "axios";
import { useKeycloak } from "@react-keycloak/web";
import { useAuthentication } from "./Authentication";
import { useNavigate } from "react-router-dom";
import { BASE_API_URL } from "./Constant";

export const AxiosHTTPClient = () => {
  const { keycloak, initialized } = useKeycloak();
  const { principal, logout } = useAuthentication();
  const navigate = useNavigate();
  
  const axiosClient = axios.create({ baseURL: BASE_API_URL });

  axiosClient.interceptors.request.use(function (config: any) {
    try {
      if (principal?.authprovider === "KEYCLOAK") {
        config.headers.Authorization = "Bearer " + keycloak?.token;
        return config;
      } else {
        config.headers.Authorization = "PAYMENT_TOKEN " + principal?.token;
        return config;
      }
    } catch (ex) {
      logout();
      navigate("/login");
    }
  });

  axiosClient.interceptors.response.use(
    function (response: any) {
      return response;
    },
    function (error: any) {
      return Promise.reject(error);
    }
  );
  return axiosClient;
};
