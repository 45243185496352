import React, { useEffect, useState } from "react";
import { Table, Button } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { NavLink } from "react-router-dom";
import Sidebar from "../Sidebar";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteSweepIcon from "@mui/icons-material/DeleteSweep";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import TablePagination from "@mui/material/TablePagination";
import { AxiosHTTPClient } from "../../util/AxiosInstance";
import { PaymentTypeService } from "../../service/PaymentTypeService";
import { BASE_API_URL } from "../../util/Constant";
import { useNotifications } from "reapop";

let style = {
  marginTop: "60px",
};

export default function PaymentTypesRead() {
  const Poorvika = require("../../asset/Poorvika.png");
  const [apiData, setApiData] = useState([]);
  const [page, setPage] = useState(0);
  const perPage = 10;
  const AxiosInstance = AxiosHTTPClient();
  const { notify } = useNotifications();

  //service Api
  const paymentTypeService: PaymentTypeService = new PaymentTypeService(
    AxiosInstance
  );

  const [isChecked, setChecked] = useState<any[]>([]);
  const handleDelete = (e: any) => {
    const { value, checked } = e.target;
    if (checked) {
      setChecked([...isChecked, value]);
    } else {
      setChecked(isChecked.filter((e) => e !== value));
    }
  };

  const handleSelectAll = (e: any) => {
    const { value, checked } = e.target;
    let checkedData: any = [];
    if (checked)
      apiData.map((item: any) => {
        checkedData = [...checkedData, item.id];
      });
    setChecked(checkedData);
  };

  //Getdata
  const getData = async () => {
    try {
      paymentTypeService.getlist().then((res) => {
        setApiData(res.data.responce);
      });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  // //delete data
  // const onDelete = (id: any) => {
  //   var option = window.confirm(`Are you sure delete`);
  //   if (option) {
  //     paymentTypeService.deletePayment(id).then((res) => {
  //       notify({ message: res.data.message, status: "success" });
  //       getData();
  //     });
  //   }
  // };

  // //Deteteall
  // const alldelete = async () => {
  //   var option = window.confirm(`Are you sure delete`);
  //   if (isChecked) {
  //     if (option) {
  //       paymentTypeService.deleteall({ isChecked }).then((res) => {
  //         notify({ message: res.data.message, status: "success" });
  //         getData();
  //       });
  //     }
  //   }
  // };

  return (
    <>
      <div style={{ display: "flex" }}>
        <Sidebar />
        <div className="Paymemtform">
          <div className="horizontalline">
            <div>
              <h3>Payment Mapping</h3>
            </div>
            <div>
              <NavLink to="/paymenttypes_form">
                <Button
                  className="w3-button w3-teal"
                  style={{
                    color: "white",
                    backgroundColor: "rgb(255 144 0)",
                  }}
                >
                  <AddIcon />
                  Add
                </Button>
              </NavLink>
            </div>
          </div>

          {/* {isChecked.length > 1 && (
            <Button
              style={{
                color: "white",
                backgroundColor: "rgb(255 144 0)",
              }}
              onClick={alldelete}
            >
              <DeleteSweepIcon />
              Delete All
            </Button>
          )} */}

          <Table celled style={style}>
            <Table.Header>
              <Table.Row>
                {/* <Table.HeaderCell>
                  <input type="checkbox" onChange={(e) => handleSelectAll(e)} />
                </Table.HeaderCell> */}

                <Table.HeaderCell style={{ textAlign: "center" }}>
                  Payment Method Name
                </Table.HeaderCell>
                <Table.HeaderCell style={{ textAlign: "center" }}>
                  Payment Gateway
                </Table.HeaderCell>
                <Table.HeaderCell style={{ textAlign: "center" }}>
                  Payment Gateway Type
                </Table.HeaderCell>

                <Table.HeaderCell style={{ textAlign: "center" }}>
                  Card type
                </Table.HeaderCell>
                <Table.HeaderCell style={{ textAlign: "center" }}>
                  Channels
                </Table.HeaderCell>
                <Table.HeaderCell style={{ textAlign: "center" }}>
                  Touch Point
                </Table.HeaderCell>
                <Table.HeaderCell style={{ textAlign: "center" }}>
                  Touch Point Type
                </Table.HeaderCell>
                <Table.HeaderCell style={{ textAlign: "center" }}>
                  Platform
                </Table.HeaderCell>
                <Table.HeaderCell style={{ textAlign: "center" }}>
                  Icon
                </Table.HeaderCell>
                <Table.HeaderCell style={{ textAlign: "center" }}>
                  Sort order
                </Table.HeaderCell>
                <Table.HeaderCell style={{ textAlign: "center" }}>
                  Status
                </Table.HeaderCell>
                <Table.HeaderCell style={{ textAlign: "center" }}>
                  Offers Status
                </Table.HeaderCell>
                <Table.HeaderCell style={{ textAlign: "center" }}>
                  Update
                </Table.HeaderCell>
                {/* <Table.HeaderCell>Delete</Table.HeaderCell> */}
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {apiData
                .slice(page * perPage, (page + 1) * perPage)
                .map((data: any, index: any) => {
                  return (
                    <Table.Row key={index}>
                      {/* <Table.Cell>
                        <input
                          type="checkbox"
                          value={data.id}
                          checked={isChecked.find((item) => item === data.id)}
                          onChange={(e) => handleDelete(e)}
                        />
                      </Table.Cell> */}

                      <Table.Cell key={index} className="transformCap">
                        {data.name}
                      </Table.Cell>
                      <Table.Cell key={index} className="transformCap">
                        {data.code}
                      </Table.Cell>
                      <Table.Cell className="transformCap">
                        {data.type}
                      </Table.Cell>

                      <Table.Cell className="transformCap">
                        {data.card_type}
                      </Table.Cell>
                      <Table.Cell className="transformCap">
                        {JSON.parse(data.channel).join(",")}
                      </Table.Cell>
                      <Table.Cell>
                        {JSON.parse(data.app_type).join(",")}
                      </Table.Cell>
                      {/* Environment */}
                      <Table.Cell className="transformCap">
                        {JSON.parse(data.mode).join(",")}
                      </Table.Cell>
                      <Table.Cell>
                        {JSON.parse(data.platform).join(",")}
                      </Table.Cell>
                      <Table.Cell>
                        <img
                          src={
                            data.icon
                              ? `${BASE_API_URL}/payment_images/${data.icon}`
                              : Poorvika
                          }
                          alt="Poorvika"
                          sizes="small"
                          width={75}
                          height={75}
                        />
                      </Table.Cell>
                      <Table.Cell style={{ textAlign: "center" }}>
                        {data.sort_by}
                      </Table.Cell>
                      <Table.Cell style={{ textAlign: "center" }}>
                        {data.status == 1 ? "Active" : "Inactive"}
                      </Table.Cell>
                      <Table.Cell style={{ textAlign: "center" }}>
                        {data.offerstatus == 1 ? "Enable" : "Disable"}
                      </Table.Cell>
                      <Table.Cell style={{ textAlign: "center" }}>
                        <Link to={`/paymenttypes_update/${data.id}`}>
                          <Button
                            style={{
                              color: "white",
                              backgroundColor: "rgb(255 144 0)",
                            }}
                          >
                            <EditIcon />
                          </Button>
                        </Link>
                      </Table.Cell>
                      {/* <Table.Cell>
                        <Button
                          style={{
                            color: "white",
                            backgroundColor: "rgb(255 144 0)",
                          }}
                          onClick={() => onDelete(data.id)}
                        >
                          <DeleteIcon />
                        </Button>
                      </Table.Cell> */}
                    </Table.Row>
                  );
                })}
            </Table.Body>
          </Table>
          {apiData.length > 0 ? (
            <TablePagination
              component="div"
              count={apiData.length}
              page={page}
              onPageChange={(e, newPage) => setPage(newPage)}
              rowsPerPage={perPage}
              rowsPerPageOptions={[]}
            />
          ) : (
            <div>No data found</div>
          )}
        </div>
      </div>
    </>
  );
}
