import React, { useEffect, useState } from "react";
import { Form, Button } from "semantic-ui-react";
import { useForm } from "react-hook-form";
import Sidebar from "../Sidebar";
import { NavLink, useParams, useNavigate } from "react-router-dom";
import CancelIcon from "@mui/icons-material/Cancel";
import { AxiosHTTPClient } from "../../util/AxiosInstance";
import { PaymentGatewayTypeService } from "../../service/PaymentGatewayTypeService";
import { useNotifications } from "reapop";

type FormValues = {
  payment_gateway_id: string;
  payment_gateway_type: string;
  gatewaytype_ref_name: string;
  status: number;
};

const PaymentGateway: React.FC<any> = () => {
  const { notify } = useNotifications();
  const {
    register,
    handleSubmit,
    setError,
    setValue,
    formState: { errors },
  } = useForm<FormValues>();

  let history = useNavigate();
  const params = useParams();
  const [GatewayTypeName, setGatewayTypeName] = useState([]);

  const AxiosInstance = AxiosHTTPClient();

  //service Api
  const paymentgatewayTypeService: PaymentGatewayTypeService =
    new PaymentGatewayTypeService(AxiosInstance);

  //get data
  const getGatewayType = async () => {
    const res = await paymentgatewayTypeService.getGatewayTypeByID(params.id);

    const paymentmethod_details = res.data.responce;

    setValue("payment_gateway_id", paymentmethod_details.payment_gateway_id);
    setValue(
      "payment_gateway_type",
      paymentmethod_details.payment_gateway_type
    );
    setValue(
      "gatewaytype_ref_name",
      paymentmethod_details.gatewaytype_ref_name
    );
    setValue("status", paymentmethod_details.status);
  };

  const PaymentNamedata = () => {
    paymentgatewayTypeService.getPaymentGatewayName().then((getData) => {
      setGatewayTypeName(getData.data.responce);
      setValue("payment_gateway_id", getData.data[0].responce.id);
    });
  };

  useEffect(() => {
    getGatewayType();
    PaymentNamedata();
  }, []);

  //Update data
  const sendDataToAPI = (data: FormValues) => {
    const Updatedata: any = {
      data: data,
      id: params.id,
    };
    paymentgatewayTypeService.gatewayTypeUpdate(Updatedata).then((res: any) => {
      // if (res.data.status === 0) {
      //   setError("payment_gateway_type", {
      //     message: res.data.message,
      //   });
      // } else
      if (res.data.status === false) {
        setError("status", {
          message: res.data.message,
        });
      } else {
        notify({ message: res.data.message, status: "success" });
        history("/paymentgatewaytype");
      }
    });
  };

  return (
    <>
      <div style={{ display: "flex" }}>
        <Sidebar />
        <div className="Paymemtform">
          <h3>Update Payment Gateway Type</h3>

          <Form onSubmit={handleSubmit(sendDataToAPI)} autoComplete="off">
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <NavLink to="/paymentgatewaytype">
                <Button className="w3-button w3-teal">
                  <CancelIcon />
                  Cancel
                </Button>
              </NavLink>
              <Button
                style={{ color: "white", backgroundColor: "rgb(255 144 0)" }}
                type="submit"
              >
                Update
              </Button>
            </div>

            <Form.Field className="form_input">
              <label>Payment Gateway Name</label>
              <select {...register("payment_gateway_id")}>
                {GatewayTypeName.map((value: any) => (
                  <option key={value.id} value={value.id}>
                    {value.payment_gateway_name}
                  </option>
                ))}
              </select>
            </Form.Field>

            <Form.Field className="form_input">
              <label>
                Payment Gateway Type <span style={{ color: "red" }}>*</span>
              </label>
              <input
                placeholder="Payment Gateway Type"
                {...register("payment_gateway_type", {
                  required: "Payment Gateway Type is required.",
                })}
                maxLength={50}
                id="payment_gateway_type"
              />

              <div style={{ color: "red" }}>
                {errors.payment_gateway_type &&
                  errors.payment_gateway_type.message}
              </div>
              <p style={{ color: "red" }}>
                Note: Specify the Payment Gateway Type accurately for proper
                functionality on the payment page.
              </p>
            </Form.Field>

            <Form.Field className="form_input">
              <label>Type Name</label>
              <input
                placeholder="Type Name"
                {...register("gatewaytype_ref_name")}
                id="gatewaytype_ref_name"
                maxLength={50}
              />
            </Form.Field>

            <Form.Field className="form_input">
              <label>Status</label>
              <select {...register("status")} id="status">
                <option value="1">Active</option>
                <option value="0">Inactive</option>
              </select>
              <div style={{ color: "red" }}>
                {errors.status && errors.status.message}
              </div>
            </Form.Field>
          </Form>
        </div>
      </div>
    </>
  );
};

export default PaymentGateway;
