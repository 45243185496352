import { AxiosInstance } from "axios";

export class PaymentNameService {
  private httpClient: AxiosInstance;
  private path: string = "paymentmethodname";

  constructor(httpClient: AxiosInstance) {
    this.httpClient = httpClient;
  }

  public async paymentAdd(data: any) {
    return await this.httpClient.post(`${this.path}/add/`, data, {
      withCredentials: false,
    });
  }

  public async getlist() {
    return await this.httpClient.get(`${this.path}`, {
      withCredentials: false,
    });
  }

  public async getFilterList(data: { eventFilterName: any; eventStatus: any }) {
    return await this.httpClient.get(
      `${this.path}?name=${data.eventFilterName}&&status=${data.eventStatus}`,
      {
        withCredentials: false,
      }
    );
  }

  public async getpaymentnameByID(id: any) {
    return await this.httpClient.get(`${this.path}/getpaymentmethod/${id}`, {
      withCredentials: false,
    });
  }

  public async getpaymentUpdate(updatedata: { data: any; id: any }) {
    return await this.httpClient.put(
      `${this.path}/updatepaymentmethod/${updatedata.id}`,
      updatedata.data,
      {
        withCredentials: false,
      }
    );
  }

  public async sync() {
    return await this.httpClient.get(`${this.path}`, {
      withCredentials: false,
    });
  }

  public async deletePayment(id: any) {
    return await this.httpClient.delete(
      `${this.path}/deletepaymentmethod/${id}`,
      {
        withCredentials: false,
      }
    );
  }

  public async deleteall(data: any) {
    return await this.httpClient.post(`${this.path}/deleteAll/`, data, {
      withCredentials: false,
    });
  }
}
