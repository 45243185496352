import React from "react";
import { SidebarData } from "./SidebarData";
import { Link } from "react-router-dom";

const Sidebar = () => {
  return (
    <div className="sidebar">
      <ul className="sidebarlist">
        {SidebarData.map((value, key) => {
          return (
            <li key={key}>
              <Link
                to={value.link}
                id={window.location.pathname === value.link ? "active" : ""}
                className="row"
              >
                <div id="sidebarIcon">{value.icon}</div>
                <div id="sidebarTitle">{value.title}</div>
              </Link>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default Sidebar;
