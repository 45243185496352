import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Form, Button } from "semantic-ui-react";
import Sidebar from "../Sidebar";
import { AxiosHTTPClient } from "../../util/AxiosInstance";
import { StoreDetailsService } from "../../service/StoreDetailsService";
import { useNotifications } from "reapop";
import CancelIcon from "@mui/icons-material/Cancel";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import Divider from "@mui/material/Divider";
import { Typography } from "@mui/material";
import { NavLink, useNavigate } from "react-router-dom";

type FormValues = {
  code: string;
  store_id: string;
  imei: string;
  pos_id: string;
  device_no: string;
  hw_id: string;
  merchant_id: string;
  security_token: string;
  merchant_name: string;
  store_name: string;
  // merchant_code: string;
};

const StoreDetailsInsert: React.FC<any> = () => {
  const { notify } = useNotifications();
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<FormValues>();

  const [apiData, setApiData] = useState([]);
  const [selectedFile, setSelectedFile] = useState("");
  const [isSelected, setIsSelected] = useState(false);
  let history = useNavigate();

  const AxiosInstance = AxiosHTTPClient();
  //service Api
  const storeDetailsService: StoreDetailsService = new StoreDetailsService(
    AxiosInstance
  );

  //Add Store Details
  const sendDataToAPI = (data: FormValues) => {
    storeDetailsService.storeDetailsAdd(data).then((res) => {
      if (res.data.status === false) {
        // notify({ message: res.data.message, status: "error" });
        setError("store_id", {
          message: res.data.message,
        });
      } else if (res.data.status === 0) {
        setError("device_no", {
          message: res.data.message,
        });
      } else if (res.data.status === 202) {
        setError("hw_id", {
          message: res.data.message,
        });
      }else {
        getData();
        notify({ message: res.data.message, status: "success" });
        history("/storedetails");
      }
    });
  };

  const changeHandler = (event: any) => {
    setSelectedFile(event.target.files[0]);
    setIsSelected(true);
  };

  const changecancel = (event: any) => {
    setIsSelected(false);
    (document.getElementById("csvrefresh") as HTMLInputElement).value = "";
  };

  //Getdata
  const getData = async () => {
    try {
      storeDetailsService.getlist().then((res) => {
        (document.getElementById("csvrefresh") as HTMLInputElement).value = "";
        setApiData(res.data.data);
      });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  //Bulk Upload Store Details
  const BulkUpload = () => {
    const formData = new FormData();
    if (selectedFile != null) {
      formData.append("file", selectedFile);

      storeDetailsService
        .BulkUpload(formData)
        .then((res: any) => {
          if (res.status === 200) {
            getData();
            notify({ message: res.data.message, status: "success" });
            history("/storedetails");
            getData();
          }

          setIsSelected(false);
          if (!res.ok) {
            return Promise.reject(res);
          }
          return res.json();
        })
        .catch((err: any) => {
          console.error(err);
        });
    }
  };

  return (
    <>
      <div style={{ display: "flex" }}>
        <Sidebar />
        <div className="Paymemtform">
          <h3 className="horizontalline">Add Store Details</h3>

          <Form onSubmit={handleSubmit(sendDataToAPI)} autoComplete="off">
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <NavLink to="/storedetails">
                <Button className="w3-button w3-teal">
                  <CancelIcon />
                  Cancel
                </Button>
              </NavLink>
              <Button
                type="submit"
                style={{ color: "white", backgroundColor: "rgb(255 144 0)" }}
              >
                Submit
              </Button>
            </div>
            <div className="form_column">
              <Form.Field className="form_input">
                <label>
                  Branch code <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  placeholder="Branch code"
                  {...register("code", {
                    required: "Branch code is required.",
                  })}
                  id="code"
                  maxLength={30}
                />

                <div style={{ color: "red" }}>
                  {errors.code && errors.code.message}
                </div>
              </Form.Field>

              <Form.Field className="form_input">
                <label>
                  Merchant Store POS Code{" "}
                  <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  placeholder="Merchant Store POS Code	"
                  {...register("store_id", {
                    required: "Merchant Store POS Code is required.",
                  })}
                  id="store_id"
                />

                <div style={{ color: "red" }}>
                  {errors.store_id && errors.store_id.message}
                </div>
              </Form.Field>
            </div>

            <div className="form_column">
              <Form.Field className="form_input">
                <label>
                  IMEI <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  placeholder="IMEI"
                  {...register("imei", {
                    required: "Imei is required.",
                  })}
                  id="imei"
                />
                <div style={{ color: "red" }}>
                  {errors.imei && errors.imei.message}
                </div>
              </Form.Field>

              <Form.Field className="form_input">
                <label>
                  POS ID <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  placeholder="POS ID"
                  {...register("pos_id", {
                    required: "POS ID is required.",
                  })}
                  id="pos_id"
                />

                <div style={{ color: "red" }}>
                  {errors.pos_id && errors.pos_id.message}
                </div>
              </Form.Field>
            </div>

            <div className="form_column">
              <Form.Field className="form_input">
                <label>
                  Device No <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  placeholder="Device No"
                  {...register("device_no", {
                    required: "Device No is required.",
                  })}
                  id="device_no"
                />

                <div style={{ color: "red" }}>
                  {errors.device_no && errors.device_no.message}
                </div>
              </Form.Field>

              <Form.Field className="form_input">
                <label>
                  H/W ID <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  placeholder="H/W ID"
                  {...register("hw_id", {
                    required: "H/W ID is required.",
                  })}
                  id="hw_id"
                />

                <div style={{ color: "red" }}>
                  {errors.hw_id && errors.hw_id.message}
                </div>
              </Form.Field>
            </div>

            <div className="form_column">
              <Form.Field className="form_input">
                <label>
                  Merchant ID <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  placeholder="Merchant ID"
                  {...register("merchant_id", {
                    required: "Merchant ID is required.",
                  })}
                  id="merchant_id"
                />

                <div style={{ color: "red" }}>
                  {errors.merchant_id && errors.merchant_id.message}
                </div>
              </Form.Field>

              <Form.Field className="form_input">
                <label>
                  Security Token No <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  placeholder="Security Token No"
                  {...register("security_token", {
                    required: "Security Token No is required.",
                  })}
                  id="security_token"
                />

                <div style={{ color: "red" }}>
                  {errors.security_token && errors.security_token.message}
                </div>
              </Form.Field>
            </div>

            <div className="form_column">
              <Form.Field className="form_input">
                <label>
                  Merchant Name <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  placeholder="Merchant Name"
                  {...register("merchant_name", {
                    required: "Merchant Name is required.",
                  })}
                  id="merchant_name"
                  maxLength={100}
                />

                <div style={{ color: "red" }}>
                  {errors.merchant_name && errors.merchant_name.message}
                </div>
              </Form.Field>

              <Form.Field className="form_input">
                <label>
                  Store Name <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  placeholder="Store Name"
                  {...register("store_name", {
                    required: "Store Name is required.",
                  })}
                  id="store_name"
                  maxLength={100}
                />

                <div style={{ color: "red" }}>
                  {errors.store_name && errors.store_name.message}
                </div>
              </Form.Field>
            </div>

            {/* <div className="form_column">
              <Form.Field className="form_input">
                <label>
                  Merchant Store POS Code{" "}
                  <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  placeholder="Merchant Store POS Code"
                  {...register("merchant_code", {
                    required: "Merchant Store POS Code is required.",
                  })}
                  id="merchant_code"
                />

                <div style={{ color: "red" }}>
                  {errors.merchant_code && errors.merchant_code.message}
                </div>
              </Form.Field>
            </div> */}
          </Form>

          <Divider variant="middle" style={{ marginTop: "10px" }} />

          <Form autoComplete="off">
            <div
              className="form_column"
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Form.Field className="form_input">
                <h3 className="horizontalline">Store Details Bulk upload</h3>

                <input
                  type="file"
                  name="file"
                  onChange={changeHandler}
                  accept=".csv,.xlsx"
                  className="filedesign"
                  id="csvrefresh"
                />
              </Form.Field>

              <div>
                <a
                  style={{
                    color: "#0fa5e2",
                    display: "flex",
                    alignItems: "center",
                    marginTop: "40px",
                  }}
                  href={process.env.PUBLIC_URL + "/csv/storedetails.csv"}
                  download
                >
                  <CloudDownloadIcon style={{ color: "#0fa5e2" }} />
                  <Typography sx={{ ml: 1 }}>Sample file</Typography>
                </a>
              </div>
            </div>
            {isSelected && (
              <div style={{ marginLeft: "10px" }}>
                <Button className="w3-button w3-teal" onClick={changecancel}>
                  <CancelIcon />
                  Cancel
                </Button>
                <Button
                  style={{
                    color: "white",
                    backgroundColor: "rgb(255 144 0)",
                  }}
                  type="submit"
                  onClick={BulkUpload}
                >
                  Upload
                </Button>
              </div>
            )}
          </Form>
        </div>
      </div>
    </>
  );
};

export default StoreDetailsInsert;