import React, { useEffect } from "react";
import { Form, Button } from "semantic-ui-react";
import { NavLink, useParams, useNavigate } from "react-router-dom";
import Sidebar from "../Sidebar";
import CancelIcon from "@mui/icons-material/Cancel";
import { useForm } from "react-hook-form";
import { AxiosHTTPClient } from "../../util/AxiosInstance";
import { PaymentNameService } from "../../service/paymetNameService";
import { useNotifications } from "reapop";

type FormValues = {
  name: string;
  status: number;
};

const Update: React.FC<any> = () => {
  const { notify } = useNotifications();
  const {
    register,
    handleSubmit,
    setError,
    setValue,
    formState: { errors },
  } = useForm<FormValues>();

  let history = useNavigate();

  const AxiosInstance = AxiosHTTPClient();
  //service Api
  const paymentNameService: PaymentNameService = new PaymentNameService(
    AxiosInstance
  );

  const params = useParams();

  const getpaymentname = async () => {
    const res = await paymentNameService.getpaymentnameByID(params.id);
    const paymentname_details = res.data.responce;
    setValue("name", paymentname_details.name);
    setValue("status", paymentname_details.status);
  };

  useEffect(() => {
    getpaymentname();
  }, []);

  //Update data
  const sendDataToAPI = (data: FormValues) => {
    const Updatedata: any = {
      data: data,
      id: params.id,
    };
    paymentNameService.getpaymentUpdate(Updatedata).then((res) => {
      if (res.data.status === false) {
        setError("name", {
          message: res.data.message,
        });
      } else if (res.data.status === false) {
        setError("status", {
          message: res.data.message,
        });
      } else {
        notify({ message: res.data.message, status: "success" });
        history("/payment_name");
      }
    });
  };

  return (
    <>
      <div style={{ display: "flex" }}>
        <Sidebar />

        <div className="Paymemtform">
          <h3>Update Payment Method </h3>

          <Form onSubmit={handleSubmit(sendDataToAPI)} autoComplete="off">
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <NavLink to="/payment_name">
                <Button className="w3-button w3-teal">
                  <CancelIcon /> Cancel
                </Button>
              </NavLink>
              <Button
                style={{ color: "white", backgroundColor: "rgb(255 144 0)" }}
                type="submit"
              >
                Update
              </Button>
            </div>
            <Form.Field className="form_input">
              <span>
                <label>
                  Name <span style={{ color: "red" }}>*</span>
                </label>
              </span>

              <input
                placeholder="Payment Method"
                {...register("name", {
                  required: "Payment Method is required.",
                })}
                // onChange={(e) => {
                //   setValue("name", e.target.value.replace(/[^A-Za-z]/gi, ""));
                // }}
                maxLength={50}
                id="name"
              />

              <div style={{ color: "red" }}>
                {errors.name && errors.name.message}
              </div>
            </Form.Field>
            <Form.Field className="form_input">
              <label>Status</label>
              <select {...register("status")} id="status">
                <option value="1">Active</option>
                <option value="0">Inactive</option>
              </select>
              <div style={{ color: "red" }}>
                {errors.status && errors.status.message}
              </div>
            </Form.Field>
          </Form>
        </div>
      </div>
    </>
  );
};
export default Update;
