import "./App.css";
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from "../src/components/Header";
import Signup from "../src/components/Signup";
import Login from "../src/components/Login";
import { useAuthentication } from "../src/util/Authentication";
import { PrivateRouter } from "../src/util/PrivateRouter";
import PaymentName from "./components/read/payment_name";
import PaymentHistory from "./components/read/paymentHistory";
import Update from "./components/update/payment_name_update";
import PaymentMethodRead from "./components/read/payment_code_read";
import PaymentMethodUpdate from "./components/update/payment_code_update";
import PaymentGatewayType from "./components/read/PaymentGatewayType";
import PaymentGatewayUpdate from "./components/update/PaymentGatewayType";
import PaymentTypesInsert from "./components/create/payment_type_form";
import PaymentTypesRead from "./components/read/payment_types_read";
import PaymentTypesUpdate from "./components/update/paymenttypes_update";
import StoreDetailsInsert from "./components/create/storedetailsform";
import StoreDetails from "./components/read/storeDetails";
import StoreDetailsUpdate from "./components/update/storeDetailsupdate";
import Tableform from "./components/create/Tableform";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import keycloakConfig from "./keycloakConfig";
import { BASE_DOMAIN_URL } from "./util/Constant";
import { NotificationsProvider, POSITIONS, setUpNotifications } from "reapop";
import NotificationPopup from "./components/NotificationPopup";

setUpNotifications({
  defaultProps: {
    position: POSITIONS.topCenter,
    dismissible: true,
    dismissAfter: 3000,
    allowHTML: true,
  },
});
function App() {
  const { isAuthenticated } = useAuthentication();

  return (
    <React.Fragment>
      <NotificationsProvider>
        <ReactKeycloakProvider
          authClient={keycloakConfig}
          initOptions={{
            onLoad: "check-sso",
            silentCheckSsoRedirectUri: `${BASE_DOMAIN_URL}/login`,
          }}
        >
          <Router>
            <header>
              <Header />
            </header>
            <main>
              <Routes>
                {/* Login */}
                <Route path="/" element={<Login />}></Route>
                <Route path="/login" element={<Login />}></Route>
                {/* <Route path="/signup" element={<Signup />}></Route> */}

                <Route element={<PrivateRouter />}>
                  {/* payment method name */}
                  <Route path="/payment_name" element={<PaymentName />} />
                  <Route path="/payment_name_update/:id" element={<Update />} />
                  {/* payment method */}
                  <Route
                    path="/payment_code_read"
                    element={<PaymentMethodRead />}
                  />
                  <Route
                    path="/payment_code_update/:id"
                    element={<PaymentMethodUpdate />}
                  />

                  {/* PaymentGatewayType */}
                  <Route
                    path="/paymentgatewaytype"
                    element={<PaymentGatewayType />}
                  />
                  <Route
                    path="/paymentgatewaytype/:id"
                    element={<PaymentGatewayUpdate />}
                  />

                  {/* payment gateway type */}
                  <Route
                    path="/paymenttypes_form"
                    element={<PaymentTypesInsert />}
                  />
                  <Route
                    path="/paymenttypes_read"
                    element={<PaymentTypesRead />}
                  />
                  <Route
                    path="/paymenttypes_update/:id"
                    element={<PaymentTypesUpdate />}
                  />
                  {/* File upload */}
                  <Route path="/fileupload" element={<Tableform />} />
                  {/* Payment history */}
                  <Route path="/paymenthistory" element={<PaymentHistory />} />
                  {/* Store details */}
                  <Route
                    path="/storedetailsform"
                    element={<StoreDetailsInsert />}
                  />
                  <Route path="/storedetails" element={<StoreDetails />} />
                  <Route
                    path="/storeDetailsupdate/:id"
                    element={<StoreDetailsUpdate />}
                  />
                </Route>
              </Routes>
            </main>
          </Router>
        </ReactKeycloakProvider>
        <NotificationPopup />
      </NotificationsProvider>
    </React.Fragment>
  );
}

export default App;
