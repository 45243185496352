import React, { useState } from "react";
import { Box, Typography, TextField } from "@mui/material";
import { Button } from "semantic-ui-react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { useAuthentication } from "../util/Authentication";
import { useKeycloak } from "@react-keycloak/web";
import { useCallback } from "react";
import { trim, toLower } from "lodash-es";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import LockPersonIcon from "@mui/icons-material/LockPerson";
import { BASE_API_URL, BASE_DOMAIN_URL } from "../util/Constant";

axios.defaults.withCredentials = true;

axios.interceptors.request.use(
  function (request: any) {
    request.headers.chennalName = "sakdhjsadg";

    return request;
  },
  function (error) {
    return Promise.reject(error);
  }
);

type Loginprops = {
  email?: string;
  password?: string;
};

const Login: React.FC<Loginprops> = () => {
  const history = useNavigate();
  const location = useLocation();
  const locationState: any = location.state;
  const authPage = locationState?.authPage || { pathname: "/payment_name" };
  const { isAuthenticated, principal, login } = useAuthentication();

  const [login_err, setLoginerror] = useState({ email: "", password: "" });
  const { keycloak, initialized } = useKeycloak();

  const keycloakLogin = useCallback(() => {
    keycloak?.login({ redirectUri: `${BASE_DOMAIN_URL}/login` });
  }, [keycloak]);

  const setSessionDetails = async (credentials: any) => {
    login(credentials);
  };

  const axiosClient = axios.create({ baseURL: BASE_API_URL });
  React.useEffect(() => {
    (async function loadContent() {
      if (keycloak && keycloak?.authenticated === true) {
        const parsedToken: any = keycloak.tokenParsed;
        try {
          await axiosClient.get(`userlogin/session`, {
            headers: { Authorization: `Bearer ${keycloak.token}` },
          });
          const credentials = {
            username: trim(toLower(parsedToken?.name)),
            firstname: parsedToken?.given_name,
            lastname: parsedToken?.family_name,
            authprovider: "KEYCLOAK",
            token: keycloak?.token,
            email: parsedToken?.email,
            displayName: parsedToken?.name,
          };
          await setSessionDetails(credentials);
          history(authPage);
        } catch (exception) {
          console.log("Error getting Session Details");
        }
      }
    })();
  }, [keycloak?.authenticated]);

  const [inputs, setInputs] = useState<Loginprops>({
    email: "",
    password: "",
  });

  const handleChange = (e: any) => {
    setInputs((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const loginrequest = async () => {
    const res = await axiosClient
      .post("userlogin/login", inputs)
      .catch((err) => {
        if (err.response.data.success === false) {
          return setLoginerror(err.response.data.error);
        } else {
          return err;
        }
      });
    const data = await res.data;

    if (login && data) {
      login(data);
      return data;
    }
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();

    loginrequest().then(() => {
      history("/payment_name");
    });
  };

  return (
    <div>
      <div style={{ display: "flex" }}>
        <div
          className="loginbg w-1/2 justify-center 
          items-center"
          style={{ backgroundColor: "burlywood", height: 798 }}
        ></div>
        <div
          className="w-2/5 justify-center items-center"
          style={{ marginTop: "10%" }}
        >
          <form onSubmit={handleSubmit} autoComplete="off">
            <Box
              display="flex"
              flexDirection={"column"}
              width={300}
              margin="0 auto"
            >
              <Typography
                variant="h6"
                style={{
                  fontFamily: "sans-serif",
                  fontWeight: "700",
                  margin: "0 auto",
                  fontSize: "22px",
                }}
              >
                Payment Admin
              </Typography>

              <p className="align-left">User Email</p>
              <TextField
                style={{ backgroundColor: "#a79e9e29", fontWeight: "500" }}
                name="email"
                type="email"
                variant="outlined"
                placeholder="User Email"
                margin="normal"
                value={inputs.email}
                onChange={handleChange}
              />
              {login_err ? (
                <div style={{ color: "red" }}>{login_err.email}</div>
              ) : (
                ""
              )}

              <p className="align-left">Password</p>
              <TextField
                style={{ backgroundColor: "#a79e9e29", fontWeight: "500" }}
                name="password"
                type="password"
                variant="outlined"
                placeholder="Password"
                margin="normal"
                value={inputs.password}
                onChange={handleChange}
              />
              {login_err ? (
                <div style={{ color: "red" }}>{login_err.password}</div>
              ) : (
                ""
              )}
              <div className="pt-2 float-right">
                <Button color="orange" type="submit" className="w-48 r-0">
                  <LockPersonIcon />
                  Login
                </Button>
              </div>
              <div className="pt-10 m-auto">Or</div>
            </Box>
          </form>
          <Box
            display="flex"
          >
            <div className="pt-2 m-auto">
              <Button color="orange" type="submit" onClick={keycloakLogin}>
                <AssignmentIndIcon />
                Login with SSO
              </Button>
            </div>
          </Box>
        </div>
      </div>
    </div>
  );
};

export default Login;
