import { AxiosInstance } from "axios";

export class PaymentGatewayTypeService {
  private httpClient: AxiosInstance;
  private path: string = "paymentgatewaytype";

  constructor(httpClient: AxiosInstance) {
    this.httpClient = httpClient;
  }

  public async getPaymentGatewayName() {
    return await this.httpClient.get(`${this.path}/paymentgateway/`, {
      withCredentials: false,
    });
  }

  public async paymentAdd(data: any) {
    return await this.httpClient.post(`${this.path}/add/`, data, {
      withCredentials: false,
    });
  }

  public async getlist() {
    return await this.httpClient.get(`${this.path}`, {
      withCredentials: false,
    });
  }

  public async getFilterList(data: {
    eventFilterGatewayName: any;
    eventStatus: any;
  }) {
    return await this.httpClient.get(
      `${this.path}?payment_gateway_id=${data.eventFilterGatewayName}&&status=${data.eventStatus}`,
      {
        withCredentials: false,
      }
    );
  }

  public async getGatewayTypeByID(id: any) {
    return await this.httpClient.get(`${this.path}/getgatewaytype/${id}`, {
      withCredentials: false,
    });
  }

  public async gatewayTypeUpdate(updatedata: { data: any; id: any }) {
    return await this.httpClient.put(
      `${this.path}/updategatewaytype/${updatedata.id}`,
      updatedata.data,
      {
        withCredentials: false,
      }
    );
  }

  public async sync() {
    return await this.httpClient.get(`${this.path}`, {
      withCredentials: false,
    });
  }

  public async deleteType(id: any) {
    return await this.httpClient.delete(
      `${this.path}/deletegatewaytype/${id}`,
      {
        withCredentials: false,
      }
    );
  }

  public async deleteall(data: any) {
    return await this.httpClient.post(`${this.path}/deleteAll/`, data, {
      withCredentials: false,
    });
  }
}
