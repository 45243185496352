import React, { useEffect } from "react";
import { Form, Button } from "semantic-ui-react";
import { NavLink, useParams, useNavigate } from "react-router-dom";
import Sidebar from "../Sidebar";
import CancelIcon from "@mui/icons-material/Cancel";
import { useForm } from "react-hook-form";
import { AxiosHTTPClient } from "../../util/AxiosInstance";
import { StoreDetailsService } from "../../service/StoreDetailsService";
import { useNotifications } from "reapop";

type FormValues = {
  code: string;
  store_id: string;
  imei: string;
  pos_id: string;
  device_no: string;
  hw_id: string;
  merchant_id: string;
  security_token: string;
  merchant_name: string;
  store_name: string;
  // merchant_code: string;
};

const StoreDetailsUpdate: React.FC<any> = () => {
  const { notify } = useNotifications();
  const {
    register,
    handleSubmit,
    setError,
    setValue,
    formState: { errors },
  } = useForm<FormValues>();

  let history = useNavigate();

  const AxiosInstance = AxiosHTTPClient();
  //service Api
  const storeDetailsService: StoreDetailsService = new StoreDetailsService(
    AxiosInstance
  );

  const params = useParams();

  const getStoreDetails = async () => {
    const res = await storeDetailsService.getstoreDetailsByID(params.id);
    const paymentname_details = res.data.responce;
    setValue("code", paymentname_details.code);
    setValue("store_id", paymentname_details.store_id);
    setValue("imei", paymentname_details.imei);
    setValue("pos_id", paymentname_details.pos_id);
    setValue("device_no", paymentname_details.device_no);
    setValue("hw_id", paymentname_details.hw_id);
    setValue("merchant_id", paymentname_details.merchant_id);
    setValue("security_token", paymentname_details.security_token);
    setValue("merchant_name", paymentname_details.merchant_name);
    setValue("store_name", paymentname_details.store_name);
    // setValue("merchant_code", paymentname_details.merchant_code);
  };

  useEffect(() => {
    getStoreDetails();
  }, []);

  //Update data
  const sendDataToAPI = (data: FormValues) => {
    const Updatedata: any = {
      data: data,
      id: params.id,
    };

    // if (Array.isArray(data.store_id)) data["store_id"] = data.store_id;

    storeDetailsService.storeDetailsUpdate(Updatedata).then((res) => {
      if (res.data.status === false) {
        // notify({ message: res.data.message, status: "error" });
        setError("store_id", {
          message: res.data.message,
        });
      } else if (res.data.status === 0) {
        setError("device_no", {
          message: res.data.message,
        });
      } else if (res.data.status === 202) {
        setError("hw_id", {
          message: res.data.message,
        });
      } else {
        notify({ message: res.data.message, status: "success" });
        history("/storeDetails");
      }
    });
  };

  return (
    <>
      <div style={{ display: "flex" }}>
        <Sidebar />

        <div className="Paymemtform">
          <h3 className="horizontalline">Update Store Details </h3>

          <Form onSubmit={handleSubmit(sendDataToAPI)} autoComplete="off">
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <NavLink to="/storeDetails">
                <Button className="w3-button w3-teal">
                  <CancelIcon /> Cancel
                </Button>
              </NavLink>
              <Button
                style={{ color: "white", backgroundColor: "rgb(255 144 0)" }}
                type="submit"
              >
                Update
              </Button>
            </div>
            <div className="form_column">
              <Form.Field className="form_input">
                <label>
                  Branch code <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  placeholder="Branch code"
                  {...register("code", {
                    required: "Branch code is required.",
                  })}
                  id="code"
                  maxLength={30}
                />

                <div style={{ color: "red" }}>
                  {errors.code && errors.code.message}
                </div>
              </Form.Field>

              <Form.Field className="form_input">
                <label>
                  Merchant Store POS Code{" "}
                  <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  placeholder="Merchant Store POS Code"
                  {...register("store_id", {
                    required: "Merchant Store POS Code is required.",
                  })}
                  id="store_id"
                />

                <div style={{ color: "red" }}>
                  {errors.store_id && errors.store_id.message}
                </div>
              </Form.Field>
            </div>

            <div className="form_column">
              <Form.Field className="form_input">
                <label>
                  IMEI <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  placeholder="IMEI"
                  {...register("imei", {
                    required: "Imei is required.",
                  })}
                  id="imei"
                />
                <div style={{ color: "red" }}>
                  {errors.imei && errors.imei.message}
                </div>
              </Form.Field>

              <Form.Field className="form_input">
                <label>
                  POS ID <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  placeholder="POS ID"
                  {...register("pos_id", {
                    required: "POS ID is required.",
                  })}
                  id="pos_id"
                />

                <div style={{ color: "red" }}>
                  {errors.pos_id && errors.pos_id.message}
                </div>
              </Form.Field>
            </div>

            <div className="form_column">
              <Form.Field className="form_input">
                <label>
                  Device No <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  placeholder="Device No"
                  {...register("device_no", {
                    required: "Device No is required.",
                  })}
                  id="device_no"
                />

                <div style={{ color: "red" }}>
                  {errors.device_no && errors.device_no.message}
                </div>
              </Form.Field>

              <Form.Field className="form_input">
                <label>
                  H/W ID <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  placeholder="H/W ID"
                  {...register("hw_id", {
                    required: "H/W ID is required.",
                  })}
                  id="hw_id"
                />

                <div style={{ color: "red" }}>
                  {errors.hw_id && errors.hw_id.message}
                </div>
              </Form.Field>
            </div>

            <div className="form_column">
              <Form.Field className="form_input">
                <label>
                  Merchant ID <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  placeholder="Merchant ID"
                  {...register("merchant_id", {
                    required: "Merchant ID is required.",
                  })}
                  id="merchant_id"
                />

                <div style={{ color: "red" }}>
                  {errors.merchant_id && errors.merchant_id.message}
                </div>
              </Form.Field>

              <Form.Field className="form_input">
                <label>
                  Security Token No <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  placeholder="Security Token No"
                  {...register("security_token", {
                    required: "Security Token No is required.",
                  })}
                  id="security_token"
                />

                <div style={{ color: "red" }}>
                  {errors.security_token && errors.security_token.message}
                </div>
              </Form.Field>
            </div>

            <div className="form_column">
              <Form.Field className="form_input">
                <label>
                  Merchant Name <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  placeholder="Merchant Name"
                  {...register("merchant_name", {
                    required: "Merchant Name is required.",
                  })}
                  id="merchant_name"
                  maxLength={100}
                />

                <div style={{ color: "red" }}>
                  {errors.merchant_name && errors.merchant_name.message}
                </div>
              </Form.Field>

              <Form.Field className="form_input">
                <label>
                  Store Name <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  placeholder="Store Name"
                  {...register("store_name", {
                    required: "Store Name is required.",
                  })}
                  id="store_name"
                  maxLength={100}
                />

                <div style={{ color: "red" }}>
                  {errors.store_name && errors.store_name.message}
                </div>
              </Form.Field>
            </div>

            {/* <div className="form_column">
              <Form.Field className="form_input">
                <label>
                  Merchant Store POS Code{" "}
                  <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  placeholder="Merchant Store POS Code"
                  {...register("merchant_code", {
                    required: "Merchant Store POS Code is required.",
                  })}
                  id="merchant_code"
                />

                <div style={{ color: "red" }}>
                  {errors.merchant_code && errors.merchant_code.message}
                </div>
              </Form.Field>
            </div> */}
          </Form>
        </div>
      </div>
    </>
  );
};
export default StoreDetailsUpdate;
