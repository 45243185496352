import { AxiosInstance } from "axios";

export class StoreDetailsService {
  private httpClient: AxiosInstance;
  private path: string = "storedetails";

  constructor(httpClient: AxiosInstance) {
    this.httpClient = httpClient;
  }

  public async storeDetailsAdd(data: any) {
    return await this.httpClient.post(`${this.path}`, data, {
      withCredentials: false,
    });
  }

  public async BulkUpload(data: any) {
    return await this.httpClient.post(`${this.path}/upload`, data, {
      withCredentials: false,
    });
  }

  public async getstoreDetailsByID(id: any) {
    return await this.httpClient.get(`${this.path}/${id}`, {
      withCredentials: false,
    });
  }

  public async getlist() {
    return await this.httpClient.get(`${this.path}/store`, {
      withCredentials: false,
    });
  }

  public async getFilterList(data: {
    eventFiltercode: any;
    eventStoreId: any;
    eventImei: any;
    eventPosId: any;
    eventDeviceNo: any;
    eventHwId: any;
    eventMerchantId: any;
    eventSecurityToken: any;
    eventMerchantName: any;
    eventStoreName: any;
  }) {
    return await this.httpClient.get(
      `${this.path}/sync?code=${data.eventFiltercode}&&store_id=${data.eventStoreId}
      &&imei=${data.eventImei}&&pos_id=${data.eventPosId}&&device_no=${data.eventDeviceNo}&&hw_id=${data.eventHwId}&&merchant_id=${data.eventMerchantId}&&security_token=${data.eventSecurityToken}&&merchant_name=${data.eventMerchantName}&&store_name=${data.eventStoreName}`,
      {
        withCredentials: false,
      }
    );
  }

  public async sync() {
    return await this.httpClient.get(`${this.path}/sync`, {
      withCredentials: false,
    });
  }

  public async storeDetailsUpdate(updatedata: { data: any; id: any }) {
    return await this.httpClient.put(
      `${this.path}/${updatedata.id}`,
      updatedata.data,
      {
        withCredentials: false,
      }
    );
  }

  public async deleteStoreDetails(id: any) {
    return await this.httpClient.delete(`${this.path}/${id}`, {
      withCredentials: false,
    });
  }

  public async deleteall(data: any) {
    return await this.httpClient.post(`${this.path}/deleteall/`, data, {
      withCredentials: false,
    });
  }
}
