import React, { useEffect, useState } from "react";
import { Form, Button } from "semantic-ui-react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import CancelIcon from "@mui/icons-material/Cancel";
import Sidebar from "../Sidebar";
import { NavLink } from "react-router-dom";
import { AxiosHTTPClient } from "../../util/AxiosInstance";
import { PaymentTypeService } from "../../service/PaymentTypeService";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import { useNotifications } from "reapop";

const NoMaxWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: "none",
  },
});

type FormValues = {
  name: string;
  type: string;
  code: string;
  channel: string;
  app_type: string;
  mode: string;
  card_type: string;
  sort_by: number;
  icon: string;
  platform: string;
  status: number;
  offerstatus: number;
  paymentRazorpayName: any;
};

const PaymentTypesInsert: React.FC<any> = () => {
  const AxiosInstance = AxiosHTTPClient();

  //service Api
  const paymentTypeService: PaymentTypeService = new PaymentTypeService(
    AxiosInstance
  );

  const { notify } = useNotifications();

  const {
    register,
    handleSubmit,
    setError,
    setValue,
    formState: { errors },
  } = useForm<FormValues>();

  let history = useNavigate();
  const [apiData, setNameData] = useState([]);
  const [methodData, setMethodData] = useState([]);
  const [TypeData, setTypeData] = useState([]);
  const mode_values = [{ value: "app" }, { value: "web" }];
  const channel_values = [{ value: "online" }, { value: "shop" }];
  const appType_values = [
    { name: "crm", value: 1 },
    { name: "e-commerce", value: 2 },
  ];
  const app_values = [
    { name: "Andoid", value: 1 },
    { name: "Ios", value: 2 },
  ];
  const [isSelected, setIsSelected] = useState(false);
  const [imgData, setImgData] = useState(null) as any;
  const [icon, setPaymentImage] = useState([]);
  const [mode, setPaymentMenu] = useState([]);
  const [app_type, setAppType] = useState([]);
  const [channel, setPaymentChannel] = useState([]);
  const [platform, setPlatfrom] = useState([]);
  const [checked, setChecked] = React.useState(true);
  const [paymentRazorpayName, setPaymentRazorpayName] = useState("");

  const handlerazorPay = (item: any) => {
    const index = item.target.selectedIndex;
    const el = item.target.childNodes[index];
    const option: any = el.getAttribute("id");
    setPaymentRazorpayName(option.toLowerCase());
    if (option.toLowerCase() !== "razorpay" || option !== "3") {
      setValue("card_type", "");
    }
    paymentTypeService
      .getGatewayType(item.target.value)
      .then((gatewaytypedata) => {
        setTypeData(gatewaytypedata.data.responce);
        setValue("type", gatewaytypedata.data.responce[0].id);
      });
  };

  //channel menu checkbox
  const channelCheck = (event: any) => {
    var channelCheckList: any = [];
    if (event.target.checked) {
      channelCheckList = [...channel, event.target.value];
    } else {
      channelCheckList = channel.filter(
        (item: any) => item !== event.target.value
      );
    }

    setPaymentChannel(channelCheckList);
  };

  //app_type menu checkbox
  const AppTypeCheck = (event: any) => {
    var AppTypeCheckList: any = [];
    if (event.target.checked) {
      AppTypeCheckList = [...app_type, event.target.value];
    } else {
      AppTypeCheckList = app_type.filter(
        (item: any) => item !== event.target.value
      );
    }

    setAppType(AppTypeCheckList);
  };

  //payment menu checkbox
  const menuCheck = (event: any) => {
    var menuCheckList: any = [];
    if (event.target.checked) {
      menuCheckList = [...mode, event.target.value];
    } else {
      menuCheckList = mode.filter((item: any) => item !== event.target.value);
    }

    const applist = menuCheckList.includes("app");
    if (applist == false) {
      setValue("platform", "");
      setPlatfrom([]);
    }

    setPaymentMenu(menuCheckList);
  };

  //platform checkbox
  const appCheck = (event: any) => {
    var appCheckList: any = { name: "", value: "" };
    if (event.target.checked) {
      appCheckList = [
        ...platform,
        {
          name: event.target.id,
          value: event.target.value,
        },
      ];
    } else {
      var appCheckList: any = platform.filter(
        (item: any) => item !== event.target.value
      );
    }

    setPlatfrom(appCheckList);
  };

  //Image preview
  const handlePhoto = (e: any) => {
    if (e.target.files[0]) {
      setPaymentImage(e.target.files[0]);
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setImgData(reader.result);
      });
      reader.readAsDataURL(e.target.files[0]);
      setIsSelected(true);
    }
  };

  const PaymentNamedata = () => {
    paymentTypeService.getPaymentName().then((getData) => {
      setNameData(getData.data.responce);
      setValue("name", getData.data.responce[0].id);
    });
  };

  const PaymentMethoddata = () => {
    paymentTypeService.getPaymentMethod().then((methoddata) => {
      setMethodData(methoddata.data.responce);
      setValue("code", methoddata.data.responce[0].id);
      setPaymentRazorpayName(methoddata.data.responce[0].payment_gateway_name);
      paymentTypeService
        .getGatewayType(methoddata.data.responce[0].id)
        .then((gatewaytypedata) => {
          setTypeData(gatewaytypedata.data.responce);
          setValue("type", gatewaytypedata.data.responce[0].id);
        });
    });
  };

  useEffect(() => {
    PaymentNamedata();
    PaymentMethoddata();
  }, []);

  const PaymentTypeInsert = (data: FormValues) => {
    const {
      name,
      type,
      code,
      card_type,
      channel,
      app_type,
      mode,
      platform,
      icon,
      sort_by,
      status,
      offerstatus,
    } = data;

    const formData = new FormData();
    formData.append("name", name);
    formData.append("type", type);
    formData.append("code", code);
    if (card_type !== undefined) {
      formData.append("card_type", card_type);
    } else {
      formData.append("card_type", "");
    }
    formData.append("channel", channel);
    formData.append("app_type", app_type);
    formData.append("mode", mode);
    formData.append("platform", platform);
    formData.append("icon", icon[0]);
    formData.append("sort_by", `${sort_by}`);
    formData.append("status", `${status}`);
    formData.append("offerstatus", `${offerstatus}`);

    paymentTypeService.paymentAdd(formData).then((res) => {
      // if (res.data.status == 0) {
      //   setError("name", {
      //     message: res.data.message,
      //   });
      // }
      // else
      if (res.data.status == 0) {
        setError("sort_by", {
          message: res.data.message,
        });
      } else {
        setIsSelected(false);
        notify({ message: res.data.message, status: "success" });
        history("/paymenttypes_read");
      }
    });
  };

  const Methodcontent = `Choose the method name to be displayed on the frontend of the website.`;

  const Gatwaycontent = `Select the payment gateway that matches the above payment method.`;

  const Typecontent = `Choose the payment gateway type that corresponds to the selected payment gateway.`;

  const CardTypecontent = `If you choose credit or debit, specify the card type`;

  const TouchPointTypecontent = `Select where the effect should occur`;

  const Channelcontent = `Select the channel where the effect should occur`;

  const TouchPointcontent = `Choose the touchpoint where the effect should occur`;

  const Platformcontent = `If you select the app, specify the app`;

  const Iconcontent = `Upload an image for the payment method icon`;

  const Sortordercontent = `Specify the position the payment method should occupy on the payment page`;

  const Statuscontent = `Indicate if the payment should be active`;

  const OfferStatuscontent = `Indicate if the Offers should be active`;

  return (
    <>
      <div style={{ display: "flex" }}>
        <Sidebar />
        <div className="Paymemtform">
          <h3>Add Payment Mapping </h3>

          <Form
            onSubmit={handleSubmit(PaymentTypeInsert)}
            encType="multipart/form-data"
            autoComplete="off"
          >
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <NavLink to="/paymenttypes_read">
                <Button className="w3-button w3-teal">
                  <CancelIcon />
                  Cancel
                </Button>
              </NavLink>
              <Button
                style={{
                  color: "white",
                  backgroundColor: "rgb(255 144 0)",
                }}
                type="submit"
              >
                Submit
              </Button>
            </div>
            <div>
              <Form.Field className="form_input">
                <NoMaxWidthTooltip title={Methodcontent}>
                  <label>
                    Payment Method Name <span style={{ color: "red" }}>*</span>
                  </label>
                </NoMaxWidthTooltip>
                <select {...register("name")}>
                  {apiData.map((value: any) => (
                    <option key={value.id} value={value.id}>
                      {value.name}
                    </option>
                  ))}
                </select>
                <div style={{ color: "red" }}>
                  {errors.name && errors.name.message}
                </div>
              </Form.Field>

              <Form.Field className="form_input">
                <NoMaxWidthTooltip title={Gatwaycontent}>
                  <label>
                    Payment Gateway <span style={{ color: "red" }}>*</span>
                  </label>
                </NoMaxWidthTooltip>
                <select {...register("code")} onChange={handlerazorPay}>
                  {methodData.map((value: any) => (
                    <option
                      key={value.id}
                      id={value.payment_gateway_name}
                      value={value.id}
                    >
                      {value.payment_gateway_name}
                    </option>
                  ))}
                </select>
              </Form.Field>

              <div className="form_column">
                <Form.Field className="form_input">
                  <NoMaxWidthTooltip title={Typecontent}>
                    <label>
                      Payment Gateway Type{" "}
                      <span style={{ color: "red" }}>*</span>
                    </label>
                  </NoMaxWidthTooltip>
                  <select {...register("type")}>
                    {TypeData.map((value: any) => (
                      <option key={value.id} value={value.id}>
                        {value.payment_gateway_type}
                      </option>
                    ))}
                  </select>
                </Form.Field>
              </div>

              {paymentRazorpayName == "razorpay" ||
              paymentRazorpayName == "3" ? (
                <Form.Field className="form_input">
                  <span>
                    <NoMaxWidthTooltip title={CardTypecontent}>
                      <label
                        style={{ fontWeight: "600", fontSize: ".92857143em" }}
                      >
                        Card type
                      </label>
                    </NoMaxWidthTooltip>
                  </span>
                  <input
                    placeholder="Card type"
                    {...register("card_type")}
                    id="card_type"
                  />
                </Form.Field>
              ) : (
                <></>
              )}

              {/* Channel values */}

              <div className="w-1/5">
                <Form.Field className="form_input">
                  <NoMaxWidthTooltip title={Channelcontent}>
                    <label>
                      Channel <span style={{ color: "red" }}>*</span>
                    </label>
                  </NoMaxWidthTooltip>
                  <div className="flex justify-between">
                    <div>
                      {channel_values.map((item: any, index: any) => (
                        <div key={index}>
                          <input
                            className="checkbox mt-1"
                            value={item.value}
                            type="checkbox"
                            {...register("channel", {
                              required: "Channel is required.",
                            })}
                            onChange={channelCheck}
                          />
                          <span className="ml-1">{item.value}</span>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div style={{ color: "red" }}>
                    {errors.channel && errors.channel.message}
                  </div>
                </Form.Field>
              </div>

              {/* App type to touch point (name change) values */}

              <div className="w-1/5">
                <Form.Field className="form_input">
                  <NoMaxWidthTooltip title={TouchPointcontent}>
                    <label>
                      Touch Point <span style={{ color: "red" }}>*</span>
                    </label>
                  </NoMaxWidthTooltip>
                  <div className="flex justify-between">
                    <div>
                      {appType_values.map((item: any, index: any) => (
                        <div key={index}>
                          <input
                            className="checkbox mt-1"
                            value={item.value}
                            type="checkbox"
                            {...register("app_type", {
                              required: "Touch Point is required.",
                            })}
                            onChange={AppTypeCheck}
                          />
                          <span className="ml-1">{item.name}</span>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div style={{ color: "red" }}>
                    {errors.app_type && errors.app_type.message}
                  </div>
                </Form.Field>
              </div>

              {/* Environment name changes to Touch Point Type */}

              <div className="flex">
                <div className="w-1/5">
                  <Form.Field className="form_input">
                    <NoMaxWidthTooltip title={TouchPointTypecontent}>
                      <label>
                        Touch Point Type <span style={{ color: "red" }}>*</span>
                      </label>
                    </NoMaxWidthTooltip>
                    <div className="flex justify-between">
                      <div>
                        {mode_values.map((item: any, index: any) => (
                          <div key={index}>
                            <input
                              className="checkbox mt-1"
                              value={item.value}
                              type="checkbox"
                              {...register("mode", {
                                required: "Touch Point Type is required.",
                              })}
                              onChange={menuCheck}
                            />
                            <span className="ml-1">{item.value}</span>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div style={{ color: "red" }}>
                      {errors.mode && errors.mode.message}
                    </div>
                  </Form.Field>
                </div>
                <div className="w-1/5">
                  {mode.includes("app" as never) ? (
                    <Form.Field className="form_input">
                      <NoMaxWidthTooltip title={Platformcontent}>
                        <label>
                          Platform <span style={{ color: "red" }}>*</span>
                        </label>
                      </NoMaxWidthTooltip>
                      {app_values.map((item: any, index: any) => (
                        <div key={index}>
                          <input
                            className="checkbox mt-1"
                            value={item.value}
                            defaultChecked={checked}
                            id={item.name}
                            type="checkbox"
                            {...register("platform", {
                              required: "Platform is required.",
                            })}
                            onChange={appCheck}
                          />
                          <span className="ml-1">{item.name}</span>
                        </div>
                      ))}
                      <div style={{ color: "red" }}>
                        {errors.platform && errors.platform.message}
                      </div>
                    </Form.Field>
                  ) : (
                    <></>
                  )}
                </div>
              </div>

              <Form.Field className="form_input">
                <NoMaxWidthTooltip title={Iconcontent}>
                  <label>Icon</label>
                </NoMaxWidthTooltip>
                <div>
                  <input
                    className="file"
                    type="file"
                    {...register("icon")}
                    accept="image/x-png,image/jpg,image/jpeg"
                    onChange={handlePhoto}
                  />
                  {isSelected && <img src={imgData} width={100} height={100} />}
                </div>
              </Form.Field>

              <Form.Field className="form_input">
                <span>
                  <NoMaxWidthTooltip title={Sortordercontent}>
                    <label
                      style={{ fontWeight: "600", fontSize: ".92857143em" }}
                    >
                      Sort order <span style={{ color: "red" }}>*</span>
                    </label>
                  </NoMaxWidthTooltip>
                </span>
                <input
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  {...register("sort_by", {
                    required: "Sort order is required.",
                  })}
                  placeholder="Sort order"
                  {...register("sort_by")}
                  maxLength={2}
                  id="sort_by"
                />

                <div style={{ color: "red" }}>
                  {errors.sort_by && errors.sort_by.message}
                </div>
              </Form.Field>

              <Form.Field className="form_input">
                <NoMaxWidthTooltip title={Statuscontent}>
                  <label>Status</label>
                </NoMaxWidthTooltip>
                <select {...register("status")} id="status">
                  <option value="1">Active</option>
                  <option value="0">Inactive</option>
                </select>
              </Form.Field>
              <Form.Field className="form_input">
                <NoMaxWidthTooltip title={OfferStatuscontent}>
                  <label>Offer Status</label>
                </NoMaxWidthTooltip>
                <select {...register("offerstatus")} id="offerstatus">
                  <option value="1">Enable</option>
                  <option value="0">Disable</option>
                </select>
              </Form.Field>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};

export default PaymentTypesInsert;
