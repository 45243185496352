import { AxiosInstance } from "axios";

export class PaymentCsvService {
  private httpClient: AxiosInstance;
  private path: string = "csv";

  constructor(httpClient: AxiosInstance) {
    this.httpClient = httpClient;
  }

  public async paymentAdd(data: any) {
    return await this.httpClient.post(`${this.path}/insert/`, data, {
      withCredentials: false,
    });
  }

  public async getlist() {
    return await this.httpClient.get(`${this.path}`, {
      withCredentials: false,
    });
  }

  public async deletePayment(id: any) {
    return await this.httpClient.delete(`${this.path}/delete/${id}`, {
      withCredentials: false,
    });
  }

  public async deleteall() {
    return await this.httpClient.delete(`${this.path}/deleteAll/`, {
      withCredentials: false,
    });
  }

  public async deleteExpired() {
    return await this.httpClient.delete(`${this.path}/deleteexpired`, {
      withCredentials: false,
    });
  }
}
