import React from "react";
import AnchorRoundedIcon from "@mui/icons-material/AnchorRounded";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import MergeIcon from "@mui/icons-material/Merge";
import HistoryIcon from "@mui/icons-material/History";
import AddCardIcon from "@mui/icons-material/AddCard";
import StoreIcon from "@mui/icons-material/Store";

export const SidebarData = [
  {
    title: "Payment Method",
    icon: <CreditCardIcon />,
    link: "/payment_name",
  },

  {
    title: "Payment Gateway",
    icon: <AnchorRoundedIcon />,
    link: "/payment_code_read",
  },

  {
    title: "Payment Gateway Type",
    icon: <AddCardIcon />,
    link: "/paymentgatewaytype",
  },

  {
    title: "Payment Mapping",
    icon: <MergeIcon />,
    link: "/paymenttypes_read",
  },

  {
    title: "Payment History",
    icon: <HistoryIcon />,
    link: "/paymenthistory",
  },

  {
    title: "Store Details",
    icon: <StoreIcon />,
    link: "/storedetails",
  },

  // {
  //   title: "PinePG SKU Details",
  //   icon: <FileUploadIcon />,
  //   link: "/fileupload",
  // },
];
