import { AxiosInstance } from "axios";

export class PaymentTypeService {
  private httpClient: AxiosInstance;
  private path: string = "paymentmapping";

  constructor(httpClient: AxiosInstance) {
    this.httpClient = httpClient;
  }

  public async getPaymentName() {
    return await this.httpClient.get(`${this.path}/methodnamedata/`, {
      withCredentials: false,
    });
  }

  public async getPaymentMethod() {
    return await this.httpClient.get(`${this.path}/gatewaydata/`, {
      withCredentials: false,
    });
  }

  public async getGatewayType(id: any) {
    return await this.httpClient.get(
      `${this.path}/gatewaytypedata/?payment_gatewayid=${id}`,
      {
        withCredentials: false,
      }
    );
  }

  public async paymentAdd(data: any) {
    return await this.httpClient.post(`${this.path}/add/`, data, {
      withCredentials: false,
    });
  }

  public async getlist() {
    return await this.httpClient.get(`${this.path}`, {
      withCredentials: false,
    });
  }

  public async getTypeByID(id: any) {
    return await this.httpClient.get(`${this.path}/getmapping/${id}`, {
      withCredentials: false,
    });
  }

  public async getTypeUpdate(updatedata: { data: any; id: any }) {
    return await this.httpClient.put(
      `${this.path}/update/${updatedata.id}`,
      updatedata.data,
      {
        withCredentials: false,
      }
    );
  }
}
